import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import GroupTravelHero from "./GroupTravelHero";
import GroupTravelInfo from "./GroupTravelInfo";
import { GroupTypes } from "./GroupTypes";
import { useLocation } from "react-router-dom";

export default function () {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ paddingTop: 85 }}>
      {/* <Breadcrumb showSearch={false} /> */}
      <GroupTravelHero />
      <GroupTravelInfo />
      <GroupTypes />
    </div>
  );
}
