import React from "react";
import {
  Button,
  Container,
  CssBaseline,
  Typography,
  ThemeProvider,
  createTheme,
  Box,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  dealPage: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  background: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: `url('https://www.beaches.com/blog/content/images/2022/05/Sandals-Emerald-Bay-Cabana-Friends-Group.jpg') center/cover no-repeat`,
    zIndex: -1,
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.3)",
    },
  },
  subtitle: {
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: "48px",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: "center", // Center-align the button
  },
  // button: {
  //   padding: theme.spacing(1, 2),
  //   fontSize: "18px",
  //   color: "black",
  //   backgroundColor: "white",
  //   "&:hover": {
  //     backgroundColor: "gray",
  //   },
  // },
}));

const GroupTravelHero = () => {
  const classes = useStyles();

  const handleStartPlanning = () => {
    const recipient = "info@caribbeanworldexplorer.com";
    const subject = "I'm interested in planning my next vacation";
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.dealPage}>
        <Box className={classes.background}></Box>
        <Container>
          <Typography variant="h4" className={classes.subtitle}>
            PLAN YOUR NEXT VACATION
          </Typography>
          <Typography variant="h2" className={classes.title}>
            CWE GROUP TRAVEL
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={handleStartPlanning}
              sx={{
                backgroundColor: "white",
                color: "black",
                "&:hover": {
                  backgroundColor: "white"
                }
              }}
            >
              Start Planning
            </Button>
          </div>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default GroupTravelHero;
