import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function () {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });

  const openEmailClient = () => {
    const subject = `${formData.fullName} - ${formData.subject}`;
    const body = formData.message;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ paddingTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="wrapper">
                <div className="row no-gutters">
                  <div className="col-md-7">
                    <div className="contact-wrap w-100 p-md-5 p-4">
                      <h3 className="mb-4">Contact Us</h3>
                      <div id="form-message-warning" className="mb-4"></div>
                      <div id="form-message-success" className="mb-4">
                        Your message was sent, thank you!
                      </div>
                      <form
                        id="contactForm"
                        name="contactForm"
                        className="contactForm"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" htmlFor="name">
                                Full Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={formData.fullName}
                                name="name"
                                id="name"
                                placeholder="Name"
                                onChange={(event) => {
                                  setFormData((formData) => {
                                    return {
                                      ...formData,
                                      fullName: event.target.value,
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="label" htmlFor="email">
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                value={formData.email}
                                placeholder="Email"
                                onChange={(event) => {
                                  setFormData((formData) => {
                                    return {
                                      ...formData,
                                      email: event.target.value,
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" htmlFor="subject">
                                Subject
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="subject"
                                id="subject"
                                value={formData.subject}
                                placeholder="Subject"
                                onChange={(event) => {
                                  setFormData((formData) => {
                                    return {
                                      ...formData,
                                      subject: event.target.value,
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label className="label" htmlFor="#">
                                Message
                              </label>
                              <textarea
                                name="message"
                                className="form-control"
                                id="message"
                                value={formData.message}
                                cols={30}
                                rows={4}
                                placeholder="Message"
                                onChange={(event) => {
                                  setFormData((formData) => {
                                    return {
                                      ...formData,
                                      message: event.target.value,
                                    };
                                  });
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="send-button text-center d-md-none">
                              {" "}
                              {/* Center on mobile devices */}
                              <input
                                type="button"
                                value="Send Message"
                                className="btn btn-primary"
                                onClick={openEmailClient}
                              />
                            </div>
                            <div className="send-button d-none d-md-block">
                              {" "}
                              {/* Left-align on larger screens */}
                              <input
                                type="button"
                                value="Send Message"
                                className="btn btn-primary"
                                onClick={openEmailClient}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-5 d-none d-md-flex align-items-stretch">
                    <div className="info-wrap w-100 p-5 img">
                      <img
                        src={require("../../assets/beach.jpg")} // Use the imported image as the source
                        alt="Beach Background" // Provide an alt attribute for accessibility
                        style={{
                          width: "100%", // Ensure the image covers the entire div
                          height: isSmallScreen ? "auto" : 400, // Ensure the image covers the entire div
                          objectFit: "cover", // Cover the div while maintaining aspect ratio
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-map-marker"></span>
                      </div>
                      <div className="text">
                        <p>215 East Bay St. Ste. 201k, Charleston, SC 29401</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-phone"></span>
                      </div>
                      <div className="text">
                        <p>
                          <a
                            href="tel://1(854)2330993"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            +1 (854) 233-0993
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-paper-plane"></span>
                      </div>
                      <div className="text">
                        <p>
                          <a
                            href="mailto:info@caribbeanworldexplorer.com"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            info@caribbeanworldexplorer.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-globe"></span>
                      </div>
                      <div className="text">
                        <p>
                          <a
                            href="#"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            www.caribbeanworldexplorer.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
