import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const resortTypes = [
  {
    name: "All Inclusive",
    image: "https://media.istockphoto.com/id/1211484509/photo/buffet-dinner-catering-in-party-food-and-drink-all-you-can-eat-concept.webp?b=1&s=612x612&w=0&k=20&c=1rqsvQLTKewJtMwP8w3UqLS5Ptmc8zReFoVfbDsUocg=",
  },
  {
    name: "Family",
    image: "https://media.istockphoto.com/id/939180730/photo/parents-running-along-beach-with-children-on-summer-vacation.webp?b=1&s=612x612&w=0&k=20&c=VTsI3HtrFpaKqIqj5GPdkZ7EItUDO2gAyB84g2ZktMA=",
  },
  {
    name: "Adults Only",
    image: "https://cdn.pixabay.com/photo/2016/10/26/07/56/wedding-1770860_640.jpg",
  },
  {
    name: "Honeymoon",
    image: "https://cdn.pixabay.com/photo/2018/03/14/21/52/beach-3226488_640.jpg",
  },
  {
    name: "Casino",
    image: "https://cdn.pixabay.com/photo/2016/03/13/11/45/roulette-1253622_640.jpg",
  },
  {
    name: "Exotic",
    image: "https://cdn.pixabay.com/photo/2017/12/16/22/22/bora-bora-3023437_640.jpg",
  },
  {
    name: "Golf and Spa",
    image: "https://media.istockphoto.com/id/1325095289/photo/still-life-closeup-of-a-tranquil-spa-arrangement.webp?b=1&s=612x612&w=0&k=20&c=zrk4FQJsmNpcpIpFPWbLEcCnN7rWZkvbZtHxR2UdkSM=",
  },
  {
    name: "Luxury",
    image: "https://cdn.pixabay.com/photo/2014/07/05/08/21/pool-384573_1280.jpg",
  },
  {
    name: "Cruise",
    image: "https://media.istockphoto.com/id/901705986/photo/wat-arun-and-cruise-ship-in-twilight-time-bangkok-city-thailand.jpg?s=1024x1024&w=is&k=20&c=TC_DgofLAcVMSV60pPp4gLrmbcdR0YdBxMTAu1owv7U=",
  },
];

const theme = createTheme();

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    height: "250px", // Adjust the height as needed
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&:hover::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    padding: 0,
    margin: 0,
  },
  cardContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    textAlign: "center",
  },
}));

export default function ResortTypes() {
  const classes = useStyles();

  return (
    <Grid container spacing={3} paddingX={{lg:13, md: 13, sm: 2, xs:2}} marginBottom={10} >
      {resortTypes.map((resort, index) => (
        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
          <Card
            className={classes.card}
            style={{ backgroundImage: `url(${resort.image})` }}
          >
            <CardContent className={classes.cardContent}>
              <Typography variant="h5" fontWeight={"bold"} style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>{resort.name}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
