import { Place } from "../TourPlace";

type TOUR = {
  name: string;
  places: Place[];
};
export const EXPLORER_REWARDS: TOUR[] = [
  {
    name: "Jamaica",
    places: [
      {
        name: "Dunn’s River Falls",
        description:
          "These cascading waterfalls flow down rocky and limestone steps, ultimately meeting the sea. With the assistance of a guide, you can ascend the natural terraces to reach the summit of the falls and take a refreshing dip in the pools at the bottom.",
        image:
          "https://www.tripsavvy.com/thmb/-1hX0cQuGOAPeg4BmwTbgaRS4Xs=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-479697269-596669a35f9b5816182cdccf.jpg",
        moreInfo: {
          description:
            "Dunn's River Falls is a popular location for both relaxation and adventure. Visitors can enjoy a scenic hike up the falls with the help of experienced guides and take a refreshing dip in the natural pools along the way.",
          images: [
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/60000/60315-Dunns-River-Falls.jpg",
            "https://www.sandals.co.uk/blog/content/images/2022/06/Dunn-s-River-Falls-Opening-Hours.jpg",
            "https://jis.gov.jm/media/2022/08/Dunns-River-10.jpg",
          ],
        },
      },
      {
        name: "Seven Mile Beach",
        description:
          "Also recognized as Negril Beach, this coastal gem boasts an exquisite expanse of pristine white sand and crystal-clear aqua waters, flanked by the renowned Negril Cliffs. Stretching nearly 4 miles, it’s nestled amidst lush coconut palm groves. For water sports enthusiasts, both beginners and snorkelers will delight in encountering vibrant schools of fish in these captivating and transparent waters.",
        image:
          "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/110000/110013-Negril.jpg",
        moreInfo: {
          description:
            "Seven Mile Beach, also known as Negril Beach, is not only famous for its stunning shoreline but is also home to vibrant beachfront bars and restaurants, making it an ideal spot for enjoying a tropical cocktail as you watch the sunset.",
          images: [
            "https://www.beaches.com/blog/content/images/2019/11/Sandals-Negril-Seven-Mile-Beach-Pano.jpg",
            "https://travel.usnews.com/images/Karol_KozlowskiroberthardingBeach-water-waves-sky-people-trees-nature.jpg",
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/11/cd/51/9b/seven-mile-beach.jpg?w=1200&h=1200&s=1",
          ],
        },
      },
      {
        name: "Blue Hole",
        description:
          "Nestled in the rainforest-shrouded mountains near Ocho Rios, you’ll discover the Blue Hole, a captivating sequence of picturesque waterfalls nourished by rushing cascades. It’s also referred to as Island Gully Falls. Accompanied by guides, you can venture through the area, giving you the opportunity to explore the falls and take thrilling leaps off cliffs into the invigorating pools below.",
        image:
          "https://www.sandals.com/blog/content/images/2021/11/shutterstock_227276716-1.jpg",
        moreInfo: {
          description:
            "Blue Hole, also known as Island Gully Falls, offers visitors the chance to connect with Jamaica's natural beauty. The secluded rainforest setting provides a tranquil environment for relaxation and taking in the lush surroundings.",
          images: [
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/7a/3a/1c/blue-hole-mineral-spring.jpg?w=1200&h=-1&s=1",
            "https://www.beaches.com/blog/content/images/2022/05/Blue-Hole-Lagoon-Jamaica.jpg",
            "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/jamaica/Blue_Hole_with_Flags_9174e9b8-631b-496d-a739-3db2eb2ca115.jpg",
          ],
        },
      },
      {
        name: "Mystic Mountain Rainforest",
        description:
          "At this location, you can experience the thrill of racing down the mountain on a roller coaster ride amidst the lush forest, or soar through the treetops on ziplines. For a panoramic perspective of the town and the sea, you can hop on the Sky Explorer, a chairlift that ascends the mountain. There are also opportunities for guided nature walks, exploring a butterfly and hummingbird garden, playing on a rope course, enjoying various rides, taking a dip in an infinity pool, and gliding down a waterslide.",
        image:
          "https://www.beaches.com/blog/content/images/2022/12/Mystic-Mountain-Skydrive.jpg",
        moreInfo: {
          description:
            "Mystic Mountain Rainforest not only offers exhilarating adventures but also provides a serene escape in its infinity pool area, perfect for unwinding after your thrilling experiences. You can relax, sunbathe, and enjoy the view of the Caribbean Sea.",
          images: [
            "https://images.squarespace-cdn.com/content/v1/5bf871381aef1da317ca7086/1592977231861-6UH59ETOYHR6PD9BZXIF/processed_Photo_1592977091563.jpg",
            "https://media.tacdn.com/media/attractions-splice-spp-674x446/07/1d/65/ea.jpg",
            "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,q_75,w_1200/v1/crm/jamaica/2013-Mystic-Mountain_waterslide2_d4a73dd4-5056-a36a-07e7eaf43cebc4e4.jpg",
          ],
        },
      },
      {
        name: "Rose Hall Great House",
        description:
          "Constructed in 1770, the Rose Hall Great House stands as a meticulously restored plantation house overlooking the ocean. It carries a storied history, with the legendary Annie Palmer, known as the “white witch,” once reigning here with cruelty and meeting a tragic end. Today, the house is adorned with timeless furnishings. Rose Hall offers various tours to suit your preference. If you’re intrigued by ghost stories, consider the eerie evening tour by candlelight. Guides, dressed in character, regale you with spine-tingling tales of ghost encounters that are sure to keep you on edge.",
        image:
          "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,q_75,w_1200/v1/crm/jamaica/JF_JAM13_ROSEHALL_0199_d4a484f1-5056-a36a-0704cc58d453753b.jpg",
        moreInfo: {
          description:
            "The Rose Hall Great House is renowned for its captivating evening tours. In addition to the ghost stories, you can enjoy a candlelit dinner in the grand dining room, complete with a historical reenactment of the mansion's heyday.",
          images: [
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/60000/60345-Rose-Hall-Great-House.jpg",
            "https://cdn0.weddingwire.com/vendor/382540/3_2/960/jpg/rosehall8_51_1045283.jpeg",
            "https://resmark-production.s3.amazonaws.com/images/kwx58d/d456bf3806fa27969fb00fb99c866a368f91268f/medium",
          ],
        },
      },
      {
        name: "The Bob Marley Museum",
        description:
          "For those who adore reggae music, a trip to Jamaica without touring the Bob Marley is akin to visiting Paris and missing the Eiffel Tower. Exploring the legendary musician’s former residence won’t consume much of your time, typically just an hour or two. Inside, you’ll encounter his cherished possessions, including his guitar, generously donated by his wife, Rita. You can also glimpse into his favorite rooms, preserved as they were during his lifetime. Additionally, there’s an 80-seat theater, a record store, and a gift shop where you can acquire Bob’s memorabilia.",
        image:
          "https://insidejourneys.com/wp-content/uploads/2012/04/Screen-shot-2012-04-22-at-9.29.15-PM.png",
        moreInfo: {
          description:
            "At the Bob Marley Museum, you'll find a charming courtyard where you can enjoy live reggae music performances. It's not just a museum but a place to immerse yourself in the vibrant music culture of Jamaica.",
          images: [
            "https://www.beaches.com/blog/content/images/2022/09/Bob-Marley-Museum-Jamaica-Mausoleum.jpg",
            "https://jamaica-gleaner.com/sites/default/files/media/article_images/2015/04/08/ObamaUSJamaica13.jpg",
            "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,q_75,w_1200/v1/crm/jamaica/kn_at_56hr02_d4b23c97-5056-a36a-07b61ccae3c351db.jpg",
          ],
        },
      },
    ],
  },
  {
    name: "Myrtle Beach",
    places: [
      {
        name: "The Beach",
        description:
          "Myrtle Beach's most captivating feature is its beautiful stretch of white sandy coastline that hugs the city's oceanfront. This destination offers an array of recreational options, such as boating, fishing, surfing, parasailing, windsurfing, and scuba diving. A nighttime beach stroll is equally delightful, where you can marvel at the twinkling hotel lights and listen to the soothing rhythm of the waves lapping against the shore.",
        image:
          "https://a.cdn-hotels.com/gdcs/production167/d1602/7fce964c-86d5-47a1-af25-405dbd0704cc.jpg?impolicy=fcrop&w=800&h=533&q=medium",
        moreInfo: {
          description:
            "This place is perfect for sunbathing and building sandcastles. Don't forget to bring your sunscreen and beach toys.",
          images: [
            "https://www.myrtlebeachareachamber.com/img/cache/lib24watch/Lib24watchFiles/1544/lib24watch_file/0-291e661adb04d1d1b6ff5b57f85faf16",
            "https://www.myrtlebeach.com/wp-content/uploads/2016/11/Homepage-Main.jpg",
            "https://www.vacationmyrtlebeach.com/assets/images/VMB.jpg",
          ],
        },
      },
      {
        name: "Ripley's Aquarium",
        description:
          "Visitors have the exceptional chance to venture inside a massive aquatic ecosystem, coming face to face with a myriad of marine life, including sea turtles, stingrays, and colossal sharks.",
        image:
          "https://www.ripleys.com/myrtlebeach/wp-content/uploads/sites/69/2022/09/AQ-HEADER1980x650-RIGHT.jpg",
        moreInfo: {
          description:
            "The aquarium also offers interactive touch tanks where you can get up close and personal with some fascinating sea creatures.",
          images: [
            "https://www.ripleyaquariums.com/canada/files/2022/09/Dangerous-Lagoon-Turtles.jpg",
            "https://www.paradiseresortmb.com/media/images/ripleys-aquarium-myrtle-beach-590-590x395.jpg",
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/24000/24151-Ripleys-Aquarium-Myrtle-Beach.jpg",
          ],
        },
      },
      {
        name: "Wonder Works Myrtle Beach",
        description:
          "Science education reaches new heights, as children are not only entertained but also educated. The interactive exhibits are thoughtfully categorized into Wonder Zones, each centering on specific themes such as natural disasters, physical challenges, light and sound, space exploration, and art.",
        image:
          "https://www.visitmyrtlebeach.com/sites/default/files/listing_images/WonderWorks-and-Zipline_7870A822-5056-A36A-0B0BE0A3CEAA503B-7870a6e55056a36_7870b0e5-5056-a36a-0b28564bbe62b4de.jpg",
        moreInfo: {
          description:
            "It's a great place for kids to have fun and learn at the same time. Check out the hands-on experiments and exhibits.",
          images: [
            "https://www.wonderworksonline.com/myrtle-beach/wp-content/uploads/sites/10/2020/04/Soar-and-Explore-Zip-Line.jpg",
            "https://patch.com/img/cdn20/users/1321632/20190610/112800/img-6712___10112719420.jpg",
            "https://www.myrtlebeach.com/wp-content/uploads/2015/01/Wonderworks-MyrtleBeach-Attractions-1080x720.jpg",
          ],
        },
      },
      {
        name: "Pirate Voyage",
        description:
          "Indulge in a dinner date filled with entertainment as guests are seated before a watery stage, relishing a delicious meal while witnessing the acrobatic showdown between the Sapphire and Crimson pirate crews. Adding to the spectacle, live animals like sea lions and parrots join the pirates for captivating tricks, humor, and contests. What's more, audience members have the chance to actively partake in the merriment.",
        image:
          "https://dontjustfly.com/wp-content/uploads/2020/03/piratesvoyagemyrtlebeach-5.jpg",
        moreInfo: {
          description:
            "Prepare to be amazed by the incredible pirate-themed dinner show. Fun for the whole family!",
          images: [
            "https://i0.wp.com/www.gotthetravelbugtoo.com/wp-content/uploads/2018/07/Pirates-Voyage-Dinner-Show.jpg?resize=1080%2C720&ssl=1",
            "https://api.macaronikid.com/assets/uploads/73d87817-0ea0-4681-8485-d50ead77826c.jpg",
            "https://www.visitmyrtlebeach.com/sites/default/files/styles/listing_slideshow/public/2023-05/pirates_voyage_sword_fight.jpg.webp?itok=M1pbB2Bu",
          ],
        },
      },
      {
        name: "Sky Wheel at Dusk",
        description:
          "The Myrtle Beach SkyWheel elevates its guests to impressive heights within its 42 gondolas, each accommodating six people. This ride provides excellent chances to capture stunning and uncommon photographs from a lofty vantage point. The wheel is adorned with a million enchanting LED lights, creating a truly captivating spectacle.",
        image:
          "https://skywheelmb.com/wp-content/themes/SkyWheel/images/home/bg-hero.jpg",
        moreInfo: {
          description:
            "The best time to experience the Sky Wheel is during sunset. The views are breathtaking!",
          images: [
            "https://u.realgeeks.media/acerealtysc/attractions/Myrtle_Beach_Skywheel.jpg",
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/493000/493364-coastal-south-carolina.jpg",
            "https://www.myrtlebeachonline.com/latest-news/awqbbx/picture251548378/alternates/FREE_1140/MYB_0519skywheel01",
          ],
        },
      },
      {
        name: "Myrtle Wave Water Park",
        description:
          "As the most expansive water park in Myrtle Beach, it encompasses over 20 acres filled with slides, rides, rafts, and chutes. Among the thrilling attractions are the Turbo Twisters, the heart-pounding King Cobra shoot on Snake Mountain, and the exhilarating Night Flight. However, there are also plenty of fantastic slides designed with the little ones in mind.",
        image:
          "https://www.breakers.com/wp-content/uploads/sites/13/2023/06/MYRTLE-WAVES-173-1024x720.jpg",
        moreInfo: {
          description:
            "Get ready for an adrenaline rush with the exciting water slides and attractions. There's something for everyone!",
          images: [
            "https://images.tripshock.com/photo/18983/Myrtle-Waves-Water-Park-Myrtle-Beach.jpg",
            "https://www.myrtlewaves.com/wp-content/uploads/2023/01/riptide-rockets-22-1.jpg",
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/50/07/cc/myrtle-waves-water-park.jpg?w=1200&h=1200&s=1",
          ],
        },
      },
    ],
  },
  {
    name: "Dominican Republic",
    places: [
      {
        name: "Visit Puerto Plata & Playa Dorada",
        description:
          "The picturesque Playa Dorada, a stunning beach on the northern coast of the Dominican Republic, stands out as one of the country's major tourist attractions. Unlike many beaches, it isn't crowded with boats and water sports activities, making it a serene spot for swimming and snorkeling. Beyond the resorts, you can explore pristine, undeveloped beaches framed by swaying palm trees and lush forest, offering a tranquil escape from the tourist hubbub.",
        image:
          "https://www.grandparadiseplayadorada.com/images/gallery/gallery-3.jpg",
        moreInfo: {
          description:
            "Playa Dorada is known for its soft, golden sands and calm, clear waters. It's an ideal spot for beachcombing and enjoying the sun. The area also offers opportunities for snorkeling with vibrant marine life.",
          images: [
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/0f/39/89/1f/grand-paradise-playa.jpg?w=700&h=-1&s=1",
            "https://lh5.googleusercontent.com/p/AF1QipMj9x3uis0QnpTGALncyISG7SOPtnQb-bUacfTF=s1600",
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/116000/116753-Playa-Dorada.jpg",
          ],
        },
      },
      {
        name: "Walk through Santo Domingo's Zona Colonial",
        description:
          "This is the very place where Christopher Columbus first set foot in the Americas. The streets and squares of this location are adorned with colonial architecture, much of which has been repurposed to house museums, restaurants, shops, and charming hotels. Walking through these well-preserved structures, you'll be transported back in time, providing a truly mesmerizing glimpse into another era.",
        image:
          "https://www.lopesancostabavaro.com/wp-content/uploads/2021/03/6.jpg",
        moreInfo: {
          description:
            "Zona Colonial is a UNESCO World Heritage site with cobbled streets, historic churches, and plazas. You can explore the Alcázar de Colón, a former palace, and the Catedral Primada de América, the first cathedral in the Americas.",
          images: [
            "https://assets.simpleviewinc.com/simpleview/image/upload/crm/dominicanrepublic/phoca_thumb_l_colonial_zone_nighttime-WEB_7865eb3b-df6a-434c-e35b9337215e13cc.jpg",
            "https://www.cataloniahotels.com/en/blog/wp-content/uploads/2020/05/shutterstock_702307909-scaled-e1589904104149.jpg",
            "https://fotografias.lasexta.com/clipping/cmsimages01/2021/10/05/58F6ED90-190B-4488-BF99-A999A4AACA16/104.jpg?crop=600,600,x301,y0&width=1200&height=1200&optimize=low&format=webply",
          ],
        },
      },
      {
        name: "Kiteboard on Cabarete's Kite Beach",
        description:
          "The epicenter of excitement can be found at Kite Beach, nestled just beyond a headland from Cabarete's primary beach. To reach Kite Beach, it's a pleasant 20-minute stroll along the shoreline from the town center. This compact region of Cabarete possesses a distinctive atmosphere. Mornings on the beach are tranquil, with the occasional presence of walkers, runners, swimmers, and stand-up paddleboard enthusiasts. As the afternoon progresses and the winds intensify, Kite Beach comes to life with the arrival of kiteboarders, turning the sky into a vibrant tapestry of kites soaring above.",
        image:
          "https://visitdominicanrepublic.com/wp-content/uploads/2019/12/kite-surfing-cabarete-gvaleriy-shutterstock_hero.jpg",
        moreInfo: {
          description:
            "Kite Beach is a hub for kiteboarding enthusiasts. The consistent winds and flat water make it perfect for kiteboarding. You can find kiteboarding schools and rental equipment in the area.",
          images: [
            "https://www.planetware.com/photos-large/DOM/dominican-republic-kiteboarding-kite-beach.jpg",
            "https://extremehotels.com/wp-content/uploads/2018/08/Beach-Cabarete_001-1030x685.jpg",
            "https://lh5.googleusercontent.com/p/AF1QipPKcP1dhvpNfO_vumUJXHTTGyarnDPG6HRqwPQs=s1600",
          ],
        },
      },
      {
        name: "Whale Watching in Samaná Bay",
        description:
          "Samaná Bay becomes a temporary haven for thousands of humpback whales who come to mate and give birth, rendering it one of the finest locations to witness these magnificent creatures in their natural habitat. The city of Samaná, situated on the Samaná Peninsula, serves as the primary launching point for whale-watching excursions.",
        image:
          "https://visitdominicanrepublic.com/wp-content/uploads/2019/03/whales-samana-kit-korzun-shutterstock.jpg",
        moreInfo: {
          description:
            "Samaná Bay offers whale-watching tours from mid-January to March. You can see humpback whales breaching and tail-slapping in their mating rituals.",
          images: [
            "https://cdn.getyourguide.com/img/tour/637c83e0556cc.jpeg/146.jpg",
            "https://beachtownproperty.com/wp-content/uploads/2018/02/whales5.jpg",
            "https://media.tacdn.com/media/attractions-splice-spp-674x446/06/6f/0d/d9.jpg",
          ],
        },
      },
      {
        name: "27 Waterfalls of Damajagua (27 Charcos)",
        description:
          "Venture into the narrow ravine filled with waterfalls, where you can ascend to the summit of each cascade and take exhilarating leaps into the pools beneath. This experience is not intended for the timid, as it presents a formidable challenge, but the thrill and enjoyment it offers are absolutely unbeatable.",
        image:
          "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/18/29/09/73/it-was-beautiful-all.jpg?w=600&h=500&s=1",
        moreInfo: {
          description:
            "The 27 Waterfalls of Damajagua offer a thrilling canyoning adventure. You'll hike and swim through the natural water park and jump into crystal-clear pools.",
          images: [
            "https://media.tacdn.com/media/attractions-splice-spp-674x446/09/2c/62/61.jpg",
            "https://greenglobaltravel.com/wp-content/uploads/27WaterfallsTourPuertoPlata.jpg",
            "https://puertoplatadr.com/wp-content/uploads/2021/05/damajagua-03.jpg",
          ],
        },
      },
      {
        name: "Bahia de Las Aguilas",
        description:
          "Bahia de Las Aguilas, located within Jaragua National Park, is an awe-inspiring eight-kilometer expanse of beach. This coastal gem boasts crystal-clear, serene waters and pristine white sandy shores, making it a true natural paradise.",
        image:
          "https://assets.simpleviewinc.com/simpleview/image/upload/crm/dominicanrepublic/bahia-de-las-aguilas-pedernales_793f5316-903e-f723-01ddf544d587e064.jpg",
        moreInfo: {
          description:
            "Bahia de Las Aguilas is known for its unspoiled beauty and remote location. It's perfect for a peaceful day on a secluded beach with clear waters for swimming and snorkeling.",
          images: [
            "https://www.temporadadeviajes.com/wp-content/uploads/2021/08/13-bahia-de-las-aguilas-republica-dominicana-1024x734.jpg.webp",
            "https://i0.wp.com/lacosmopolilla.com/wp-content/uploads/2020/06/Ba%C3%B1o-en-la-playa-e1591549508678.jpg?ssl=1",
            "https://cdn.statically.io/img/www.dominicanabroad.com/f=auto%2Cq=90/wp-content/uploads/2020/01/untitled-10-of-35-2.jpg",
          ],
        },
      },
    ],
  },
  {
    name: "Puerto Rico",
    places: [
      {
        name: "Old San Juan (San Juan Viejo)",
        description:
          "Visiting this place feels like a journey back in time, with its charming colonial architecture that paints a picturesque scene. It's undoubtedly one of Puerto Rico's top destinations to explore.",
        image:
          "https://wandereatwrite.com/usheeche/2021/04/umbrella-street-min.jpeg",
        moreInfo: {
          description:
            "Step into the past when you explore Old San Juan. Marvel at its colorful streets, historic forts, and vibrant culture. Don't forget to try the local cuisine!",
          images: [
            "https://endlessfamilytravels.com/wp-content/uploads/2019/04/OldSanJuan.jpg",
            "https://trvlcollective.com/wp-content/uploads/2019/02/IMG_8625.jpg",
            "https://www.discoverpuertorico.com/sites/default/files/styles/horizontal_narrow_800x600/public/2021-08/garita-old-san-juan.jpg?h=56d0ca2e&itok=xnMweLNZ",
          ],
        },
      },
      {
        name: "El Morro Fort (Fuerte San Felipe del Morro)",
        description:
          "This is a prominent tourist destination where you can delve into dungeons, meander through walkways, traverse ramps, and navigate tunnels, many of which were constructed during the mid to late 1800s. Don't forget to ascend to the crest of the walls and journey out to one of the lookout towers to savor breathtaking ocean views.",
        image:
          "https://boricuaonline.com/wp-content/uploads/2020/09/ElMorroSanJuan05.jpg",
        moreInfo: {
          description:
            "Explore the history of El Morro Fort. Learn about its military significance and enjoy the spectacular views of the ocean from the lookout towers.",
          images: [
            "https://www.discoverpuertorico.com/sites/default/files/listing_images/profile/8024/El-Morro-DPR0-c7f2e38c5056a36_c7f2e5af-5056-a36a-08288a5f093c5ddb.jpg",
            "https://www.puertorico.com/wp-content/uploads/2022/12/el-morro-min-3.jpg",
            "https://canariolagoonhotel.com/wp-content/uploads/2016/03/El-Morro-Puerto-Rico1.jpg",
          ],
        },
      },
      {
        name: "Castillo de San Cristóbal",
        description:
          "This place is definitely worth a visit, it's still in the shadows, hiding in plain sight. Castillo de San Cristobal was designed to protect against an attack from the landward side.",
        image:
          "https://www.discoverpuertorico.com/sites/default/files/listing_images/profile/8023/San-Cristobal-Fort0-f8a2520f5056a36_f8a254ab-5056-a36a-08eb914f32e3d4a7.jpg",
        moreInfo: {
          description:
            "Discover the secrets of Castillo de San Cristóbal. Learn about its architecture and its role in protecting Puerto Rico from landward attacks.",
          images: [
            "https://media.tacdn.com/media/attractions-splice-spp-674x446/0b/27/aa/f8.jpg",
            "https://www.tripsavvy.com/thmb/IMiXAHuttY7HIkLH2DCpQb3Eqt8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/castillo-de-san-crist-bal--san-juan-515755209-5c2e283ac9e77c00018a04b4.jpg",
            "https://fotografias.lasexta.com/clipping/cmsimages02/2023/03/30/26CEDEA8-BA19-4E73-9CB2-3B845347DEA3/castillo-san-cristobal-san-juan-que-recibio-ese-nombre-que-tan-importante_98.jpg?crop=1920,1080,x0,y74&width=1900&height=1069&optimize=high&format=webply",
          ],
        },
      },
      {
        name: "El Yunque National Forest",
        description:
          "El Yunque National Forest in Puerto Rico is a captivating testament to the island's verdant natural splendor. It offers one of the most exceptional and enchanting experiences for nature lovers and adventurers.",
        image:
          "https://image-tc.galaxy.tf/wijpeg-6x5d26gt7p2pukgjjmoyqkb4n/2-el-yunque-forest_standard.jpg?crop=57%2C0%2C867%2C650",
        moreInfo: {
          description:
            "Experience the lush beauty of El Yunque National Forest. Hike through its pristine trails and be awed by its diverse flora and fauna.",
          images: [
            "https://www.pommietravels.com/wp-content/uploads/2023/02/El-Yunque-Rainforest-Waterfall-914x1280.jpg",
            "https://image-tc.galaxy.tf/wijpeg-b5xe49nvrcek8aanle3x4pic7/el-yunque-national-forest_standard.jpg?crop=76%2C0%2C1768%2C1326",
            "https://www.plateapr.com/en/wp-content/uploads/sites/2/2021/12/191-3-16229_jpeg-1024x683.jpg",
          ],
        },
      },
      {
        name: "Culebra Island (Isla Culebra)",
        description:
          "This smaller island boasts stunning beaches and verdant hills, each contributing to its distinct character. Life here unfolds at a leisurely pace, creating a laid-back and relaxed atmosphere that sets it apart.",
        image:
          "https://static1.thetravelimages.com/wordpress/wp-content/uploads/2022/11/Culebrita-Puerto-Rico-Culebra-Island.jpg",
        moreInfo: {
          description:
            "Escape to paradise on Culebra Island. Enjoy its pristine beaches, explore the hills, and soak in the relaxed atmosphere.",
          images: [
            "https://shesavesshetravels.com/wp-content/uploads/2021/12/culebra-puerto-rico-.jpg",
            "https://hare-media-cdn.tripadvisor.com/media/attractions-splice-spp-720x480/0f/ec/47/d7.jpg",
            "https://static.wixstatic.com/media/4b0ace_9c184da981b24bf58ca258e650ddfd17~mv2.jpeg/v1/crop/x_429,y_265,w_4237,h_3526/fill/w_642,h_482,al_c,q_80,usm_1.20_1.00_0.01,enc_auto/4b0ace_9c184da981b24bf58ca258e650ddfd17~mv2.jpeg",
          ],
        },
      },
      {
        name: "Catedral de San Juan Bautista",
        description:
          "This is among the most significant religious landmarks in Puerto Rico. The church has gained renown as the burial site of Ponce de Leon, the Spanish explorer known for his legendary quest for the Fountain of Youth.",
        image:
          "https://b2090723.smushcdn.com/2090723/wp-content/uploads/2022/01/DSC_0542-1200x800.jpg?lossy=0&strip=0&webp=1",
        moreInfo: {
          description:
            "Visit the historic Catedral de San Juan Bautista. Learn about its religious significance and the legend of Ponce de Leon.",
          images: [
            "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/243000/243387-Puerto-Rico-Island.jpg",
            "https://live.staticflickr.com/4862/44554226090_ba14319fe8_b.jpg",
            "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/08/9e/f1/88/photo0jpg.jpg?w=1200&h=-1&s=1",
          ],
        },
      },
    ],
  },

  {
    name: "Bahamas",
    places: [
      {
        name: "Atlantis Paradise Island",
        description:
          "Towering over the horizon on Paradise Island, this vibrant salmon-pink resort skillfully reimagines the fabled Atlantis, blending luxury accommodations, an entertainment complex, a mesmerizing aquarium, and an exhilarating water park. It ranks among the premier resorts in the Bahamas.",
        image: "https://tempo.cdn.tambourine.com/windsong/media/windsong-mega-resorts-hero-5f5297b9c9e02.jpg",
        moreInfo: {
          description:
            "Atlantis Paradise Island is not just a resort; it's a world of its own. Enjoy gourmet dining, unwind at the spa, or try your luck at the casino. Explore the incredible marine life in The Dig and Aquaventure Water Park.",
          images: [
            "https://media.cntraveler.com/photos/63f566ec9e62d3e60010cf11/16:9/w_2560,c_limit/Atlantis%20Paradise%20Island_Atlantis_The_Royal_View_From_Paradise_Lagoon.jpg",
            "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/msnbc/Components/Photos/070416/070416_bahamas_vmed_10a.jpg",
            "https://blog.familyfunatlantis.com/wp-content/uploads/atlantis-casino-4-1100x825-720x540.jpg"
          ],
        },
      },
      {
        name: "Nassau",
        description:
          "This cruise ship is a favorite choice for Caribbean travelers, offering a plethora of options to make the most of your journey. You can lounge on the pristine white shores of Cable Beach, delve into the vibrant scene of downtown and Bay Street, replete with shops, restaurants, museums, and charming colonial architecture, and indulge in souvenir shopping at the Nassau Straw Market.",
        image: "https://www.seatrade-cruise.com/sites/seatrade-cruise.com/files/CRUISE_Adventure_of_the_Seas_maiden_arrival_Nassau.jpg",
        moreInfo: {
          description:
            "Experience the epitome of tropical luxury aboard a Nassau cruise ship. Set sail to the vibrant capital of the Bahamas, Nassau, where you'll be greeted by crystal-clear waters, powdery white-sand beaches, and a vibrant culture. Whether you're seeking relaxation in paradise or thrilling adventures, this cruise ship offers a range of amenities and excursions to make your journey unforgettable. Indulge in gourmet dining, unwind by the pool, explore historic landmarks, and dive into the underwater wonders of Nassau's coral reefs. Your Nassau cruise ship vacation promises sun-soaked days and Caribbean charm, making it an ideal getaway for both relaxation and exploration.",
          images: [
            "https://www.bahamasairtours.com/wp-content/uploads/2020/10/things-to-do-in-nassau-bahamas-port.jpg",
            "https://www.cruisehive.com/wp-content/uploads/2021/07/nassau27.jpg",
            "https://i.ytimg.com/vi/Vm2My7Q5igc/maxresdefault.jpg"
          ],
        },
      },
      {
        name: "Exuma Cays Land and Sea Park",
        description:
          "Exuma Cays Land & Sea Park showcases some of the most breathtaking marine vistas in the Bahamas. The vivid, electric blue waters blend with powdery white sands, providing a stunning setting for a variety of water-based activities. Situated in the remote eastern region of the Bahamas, this park is designated as a no-take-zone and a marine protected area.",
        image: "https://natureconservancy-h.assetsadobe.com/is/image/content/dam/tnc/nature/en/photos/e/x/Exuma-Cays-Bahamas-2-JeffYonover.jpg?crop=0%2C0%2C4000%2C2200&wid=4000&hei=2200&scl=1.0",
        moreInfo: {
          description:
            "Explore the Exuma Cays and discover pristine coral reefs and abundant marine life. Snorkeling, boating, and wildlife viewing are popular activities here.",
          images: [
            "https://cruisingodyssey.com/wp-content/uploads/2018/12/ExumasMooring.jpg.png",
            "https://scontent.fmbj2-1.fna.fbcdn.net/v/t1.6435-9/187581119_113716734210898_9178154382041748085_n.jpg?stp=dst-jpg_p180x540&_nc_cat=105&ccb=1-7&_nc_sid=7f8c78&_nc_ohc=kI-AjYh15pgAX9hzRTv&_nc_ht=scontent.fmbj2-1.fna&oh=00_AfBBT853orLPGzDESrEx1kV2OZbIjghiEpY6RiL6hF5siQ&oe=65522EFA",
            "https://lp-cms-production.s3.amazonaws.com/public/2021-08/GettyRF_533421563.jpg"
          ],
        },
      },
      {
        name: "Treasure Cay Beach, Great Abaco Island",
        description:
          "Lining the eastern coastline of Great Abaco, Treasure Cay Beach consistently garners accolades as one of the world's most stunning beaches. Its mesmerizing expanse of powdery sand and crystal-clear aqua waters never fails to captivate visitors who seek solace on its inviting crescent-shaped shore. This breathtaking beach is situated within the upscale resort community of Treasure Cay, which is renowned for hosting one of the Bahamas' most well-attended fishing tournaments.",
        image: "https://www.bahamasairtours.com/wp-content/uploads/2017/09/treasure-cay-bahamas-tour-island-hop.jpg",
        moreInfo: {
          description:
            "Relax on the powdery sands of Treasure Cay Beach or enjoy water sports such as snorkeling and windsurfing. The nearby resort offers dining and entertainment options.",
          images: [
            "https://www.myoutislands.com/public_docs/blog/content/boi-news-you-must-do-these-things-when-staying-in-treasure-cay.jpg",
            "https://www.caribjournal.com/wp-content/uploads/2022/02/bahamas-beloved-resort-cover.jpg",
            "https://lh3.googleusercontent.com/p/AF1QipPbo3XS0Sr8LQ7zAk01lDk9l2Qlfkeezng9n9My=w1080-h608-p-no-v0"
          ],
        },
      },
      {
        name: "Lucayan National Park on Grand Bahama Island",
        description:
          "Lucayan National Park, located on Grand Bahama Island, is one of the three national parks on the island. It spans across 40 lush acres and is most renowned for being home to the 'world's longest charted underwater cave systems.'",
        image: "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/110000/110712-Lucayan-National-Park.jpg",
        moreInfo: {
          description:
            "Discover the wonders of Lucayan National Park, including the Ben's Cave and Burial Mound caves. Hike the trails and experience the beauty of this natural park.",
          images: [
            "https://parks242.com/wp-content/uploads/2017/11/20171104_GB_Lucayan_Natl_Park_0005.jpg",
            "https://www.everyonestravelclub.com/uploads/4/1/1/4/4114355/5331029.jpg?622",
            "https://www.wanderfilledlife.com/wp-content/uploads/2019/03/Bens-Cave.jpg"
          ],
        },
      },
      {
        name: "Blue Lagoon Island",
        description:
          "On the small island known as Salt Cay, visitors can enjoy a range of attractions, including dolphin encounters, sea lion interactions, inflatable fun parks, and a pristine white-sand beach.",
        image: "https://upload.wikimedia.org/wikipedia/commons/7/75/Blue_Lagoon.JPG",
        moreInfo: {
          description:
            "Blue Lagoon Island is a paradise for animal lovers. Swim with dolphins, interact with sea lions, and enjoy a day of water activities on the beautiful beach.",
          images: [
            "https://www.shoreexcursionsgroup.com/img/tour/CANABLUDOLENCNW-2.jpg",
            "https://lh5.googleusercontent.com/p/AF1QipNT559BfIKre_u1nGxzMwO8H7uat6F9vQW3bJkg=s1600",
            "https://www.dolphinencounters.com/wp-content/uploads/2015/04/DolphinSwin2.jpg"
          ],
        },
      },
    ],
  },
  {
    name: "Panama",
    places: [
      {
        name: "Panama Canal",
        description:
          "One of the world's most renowned attractions, this engineering marvel connects the Atlantic and Pacific Oceans. Observing a colossal ship, stacked high with vibrant containers, smoothly navigating through the locks, is a remarkable spectacle that truly emphasizes the immense scale of this extraordinary accomplishment.",
        image: "https://pancanal.com/wp-content/uploads/2023/08/Canal-Connection-Agost-2023.jpg",
        moreInfo: {
          description: "The Panama Canal is a testament to human ingenuity. It stretches 50 miles from the Atlantic to the Pacific, facilitating global trade and connecting two oceans. Don't miss the Miraflores Locks Visitor Center for an up-close look at the canal's operation.",
          images: [
            "https://www.celebritycruises.com/blog/content/uploads/2022/11/visiting-the-panama-canal-canal-history.jpg",
            "https://www.usatoday.com/gcdn/-mm-/cce2cee204e839f99c234f808acf022e0328aba4/c=0-341-4608-2940/local/-/media/USATODAY/USATODAY/2013/10/24/1382630865026-XXX-PanamaCanal26.jpg",
            "https://s3-us-west-1.amazonaws.com/contentlab.studiod/getty/0e6618603a424b3997cf7cd39bd01530.jpg"
          ],
        },
      },
      {
        name: "Bocas Del Toro",
        description:
          "Bocas del Toro, Panama's primary beach destination in the Caribbean, offers a laid-back and soothing atmosphere. Activities in this beautiful area include surfing, swimming, diving, or simply unwinding on the beach.",
        image: "https://d1el5jddkxvjyd.cloudfront.net/viceroyhotelsandresorts.com-2109743334/cms/cache/v2/5c8d2ff7f0919.jpg/1920x1080/fit/80/654c0543e0a7677770b833ba2a75ceff.jpg",
        moreInfo: {
          description: "Bocas Del Toro is a tropical paradise with crystal-clear waters and vibrant coral reefs. Explore the enchanting Starfish Beach or take a boat tour to witness the archipelago's diverse marine life.",
          images: [
            "https://puertoviejo.com/wp-content/uploads/Bocas-del-Toro-Panama.jpg",
            "https://content.r9cdn.net/rimg/dimg/64/a0/9647f440-city-33356-169163d8570.jpg?width=1366&height=768&xhint=1486&yhint=1092&crop=true",
            "https://www.twowanderingsoles.com/wp-content/uploads/2021/03/Bocas-del-Toro-Panama.jpg"
          ],
        },
      },
      {
        name: "Boquete",
        description:
          "This region is one of Panama's significant coffee-producing areas and stands out as a nature and wildlife hotspot, particularly favored for hiking and birdwatching. Exploring the mountainous terrain is even more enjoyable when you have a vehicle to facilitate your tour.",
        image: "https://www.journeyera.com/wp-content/uploads/2018/07/la-artilleria-hike-boquete-09621.jpg",
        moreInfo: {
          description: "Boquete is a cool and tranquil highland town. Visit coffee plantations to learn about coffee production and enjoy the stunning vistas from the slopes of Volcán Barú. The Lost Waterfalls hike is a must for nature enthusiasts.",
          images: [
            "https://images.squarespace-cdn.com/content/v1/5d1555cc6ce5d600019bb84b/1609122328574-P68SYWZEUIN051RRZPFC/Pipeline+Trail%2C+Boquete+%7C+Toone%27s+Travels",
            "https://jessieonajourney.com/wp-content/uploads/2018/09/Lost_Waterfalls_Jungle_Panama.jpg",
            "https://www.travelgoodies.nl/wp-content/uploads/2019/02/Jungle-trekkings-4.jpg"
          ],
        },
      },
      {
        name: "Casco Viejo",
        description:
          "In this area, also referred to as Old Town, visitors can marvel at colorful buildings, enjoy stunning vistas, and explore historical ruins. As evening sets in, restaurants arrange tables and chairs along the sidewalks, and the streets come alive with beautiful illumination, creating a charming and atmospheric dining experience.",
        image: "https://images.squarespace-cdn.com/content/v1/5489eef2e4b0f02875b01243/1459396183572-0H4AS3NP7TGBKL7VUKZ0/casco-viejo-panama-org.jpg?format=1500w",
        moreInfo: {
          description: "Casco Viejo is a UNESCO World Heritage Site with colonial architecture and a vibrant cultural scene. Explore the Presidential Palace, San José Church, and indulge in the local cuisine at trendy restaurants and bars.",
          images: [
            "https://paliparan.com/wp-content/uploads/2020/09/panama-casco-viejo-27.jpg",
            "https://www.promturpanama.com/site/assets/files/3124/cascoantiguo.jpg",
            "https://images.squarespace-cdn.com/content/v1/5a48ed99e5dd5b62b3e992dd/1533911096344-9IUPT28OXES4MY1SIFY0/DSC_0075.jpg"
          ],
        },
      },
      {
        name: "Santa Catalina & Parque Nacional Coiba",
        description:
          "In this location, you'll discover cozy guesthouses and small hotels, as well as enticing little restaurants that beckon you to stop and explore. Whether you're into surfing, snorkeling, scuba diving, or even a leisurely horseback tour, there are plenty of activities to keep you engaged.",
        image: "https://www.discovercoibapanama.com/wp-content/uploads/2020/06/CICI-e1591662657560.jpg",
        moreInfo: {
          description: "Santa Catalina is a laid-back surf town with access to Coiba National Park, a UNESCO World Heritage Site. Dive with hammerhead sharks, explore untouched rainforests, or relax on the pristine beaches of Coiba Island.",
          images: [
            "https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,h_1200,q_75,w_1200/v1/clients/panama/IMG_0208_759ca4a0-143d-4699-8add-7a5b55d42937.jpg",
            "https://media.tacdn.com/media/attractions-splice-spp-674x446/06/6b/62/60.jpg",
            "https://panamawhalewatching.com/wp-content/uploads/2022/06/santa-catalina-yacht-charter-2.jpeg"
          ],
        },
      },
      {
        name: "Punta Chame",
        description:
          "This idyllic beach features an expansive stretch of soft sand, where the warm and shallow waters provide an ideal setting for children to enjoy flying kites. If you're interested in learning how to fly kites or kitesurf, you can also take lessons offered by Machete Kite and Kitesurf Panama.",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/297842362.jpg?k=4914f83c6643948657747aaddc46d1763e82f62893cbee954fa70358ff3bae7b&o=&hp=1",
        moreInfo: {
          description: "Punta Chame is a peaceful seaside escape perfect for kite enthusiasts. The consistent winds make it an ideal spot to learn kiteboarding, and the serene environment is great for family outings and water sports.",
          images: [
            "https://img10.naventcdn.com/avisos/20/00/50/13/61/32/1200x1200/77796574.jpg",
            "https://images.squarespace-cdn.com/content/v1/56210f5ce4b007035b15770c/1572654930573-FDCMEEM87MJJUD1N52JS/Panama+Kitesurfing+-+Surfing+Punta+Chame",
            "https://www.panamakitecenter.com/wp-content/uploads/2022/11/Sandbars-in-Punta-Chame-768x576.jpg"
          ],
        },
      },
    ],
  }
  ,
];
