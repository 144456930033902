import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroCarousel from "./HeroSection";
import ValuesSection from "./ValuesSection";
import LatestUpdates from "./LatestUpdates";
import FindExpert from "./FindExpert";
import ProtectAdventure from "./ProtectAdventure";
import GroupGetaways from "./GroupGetaways";
import {
  EXPERT_INFO,
  testimonialsData,
  PROTECT_ADVENTURE_INFO,
  GROUP_GETAWAYS_INFO,
} from "./LatestUpdates/constants";
import Testimonials from "./Testimonials";
import Favourites from "./Favourites";

const { image, description, title, actionText, route } = EXPERT_INFO;
const {
  adventure_actionText,
  adventure_description,
  adventure_image,
  adventure_route,
  adventure_title,
} = PROTECT_ADVENTURE_INFO;
const {
  group_actionText,
  group_description,
  group_image,
  group_route,
  group_title,
} = GROUP_GETAWAYS_INFO;
export default function () {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    // Function to check screen width and set isSmallScreen accordingly
    const checkScreenWidth = () => {
      setIsSmallScreen(window.innerWidth < 1080); // Adjust the threshold as needed
    };

    // Initial check
    checkScreenWidth();

    // Add a window resize listener to update isSmallScreen
    window.addEventListener("resize", checkScreenWidth);

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <HeroCarousel />
      <ValuesSection />
      <LatestUpdates />
      <FindExpert
        image={image}
        title={title}
        description={description}
        actionText={actionText}
        route={route}
      />
      <Favourites />
      <ProtectAdventure
        image={adventure_image}
        title={adventure_title}
        description={adventure_description}
        actionText={adventure_actionText}
        route={adventure_route}
      />
      <GroupGetaways
        image={group_image}
        title={group_title}
        description={group_description}
        actionText={group_actionText}
        route={group_route}
      />
      <Testimonials testimonials={testimonialsData} />
    </Box>
  );
}
