import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Breadcrumbs,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  InputBase,
  Divider,
  Container,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Link from "@mui/material/Link";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import { vacationDeals } from "../../pages/VacationsPage/constants";
import { cruiseDeals } from "../../pages/CruisesPage/constants";
import { isSmallScreen } from "../../utilities/screen";


interface BreadcrumbProps {
  showSearch: boolean;
}

function Breadcrumb({ showSearch }: BreadcrumbProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const pathnames = location.pathname.split("/").filter((x) => x);
  const deals = pathnames.includes("vacations") ?  [...vacationDeals] : [...cruiseDeals];

  
  const [isSmall, setIsSmall] = useState(isSmallScreen());

  // Function to update screen width state
  const updateScreenWidth = () => {
    setIsSmall(isSmallScreen());
  };

  // Listen for window resize events and update the screen width state
  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  function formatString(inputString: string) {
    if (inputString.includes("-")) {
      // If the string contains a dash, split it into words and capitalize each word.
      const words = inputString.split("-");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    } else {
      // If there is no dash, simply capitalize the entire string.
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }
  }

  function handleSearch(value: string): void {
    const selectedDeal = deals.find((deal) => deal.title === value);
    if (selectedDeal) {
      navigate(`deal/?id=${selectedDeal.id}&title=${selectedDeal.title}`);
    }
  }

  return (
    <Container
      style={{
        zIndex: 9,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: "white",
        display: "flex",
        flexDirection: isSmall ? "column" : "row",
        alignItems: isSmall? "flex-start" : "center",
        justifyContent: isSmall ? "flex-Start" : "space-between", // Add this to space elements horizontally
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          width: showSearch && !isSmall ? "50%" : "100%",
          marginBottom: isSmall ? 2 : 0,
        }}
      >
        <Link
          style={{ textDecoration: "none", width: "100%", color: "#165B76" }}
          href="/"
        >
          <HomeIcon />
        </Link>
        {pathnames.map((name, index) => {
          name = formatString(decodeURIComponent(name));
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Typography key={name} color="#DB504A">
              {name}
            </Typography>
          ) : (
            <Typography key={name} color="gray">
              {name}
            </Typography>
          );
        })}
      </Breadcrumbs>
      {showSearch && (
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          size="small"
          disableClearable
          options={deals.map((option) => option.title)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Search ${pathnames[0]}`}
              InputProps={{
                ...params.InputProps,
                type: "search",
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={(event) =>
                        handleSearch(params.inputProps.value as string)
                      }
                      sx={{ color: "#165B76" }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          sx={{
            width: !isSmall ? "50%" : "100%",
          }}
        />
      )}
    </Container>
  );
}

export default Breadcrumb;
