import React from "react";
import {
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Breadcrumb from "../../components/Breadcrumbs";

const faqData = [
  {
    question: "What does CWE do?",
    answer:
      "Caribbean World Explorer Inc. is a travel agency that books hotels, villas, cruises, flights and tours to any destination in the world. It is our goal to meet all your travel needs, whether it be booking your next vacation/trip or visa appointment.",
  },
  {
    question: "Why do I need a travel agent?",
    answer:
      "We know what it takes to plan and book a trip. We will take care of everything from booking your flights, cruises and hotels to planning your itinerary and arranging transportation. CWE also has access to exclusive deals, discounts and wholesale prices, which will help you to save money on your travel arrangements.",
  },
  {
    question: "How many hotels can we book?",
    answer: "CWE offers over 1 million hotels worldwide.",
  },
  {
    question: "Does it cost money to get a quote?",
    answer:
      "No, there is no fee to get a quote. However, for those complicated international itineraries that require custom crafting and significant involvement, we do request an intent-to-travel deposit to begin the process. This deposit is applied to reservations that are made on your behalf. If for any reason you choose not to move forward, you may transfer the deposit to a future booking.",
  },
  {
    question: "Do I need travel Insurance?",
    answer:
      "You do not need travel insurance. However, travel insurance is important and recommended as it protects the investment that you have made in your travel. Due to the fact that many trips are paid for months in advance and often times involve non-refundable payments, having travel insurance will protect you from losing your hard-earned money. Life is unpredictable so plan for the future by purchasing travel insurance.",
  },
  {
    question: "Do you book group travel?",
    answer:
      "Yes, we do. Not only do we book group travel, but we absolutely love booking them. We specialize in booking vacations for groups of all sizes. We plan family vacations/retreats, business/corporate group trips, girls’ trips, etc. Reach out to an agent right now to find out what to consider when planning yours.",
  },
  {
    question: "I have a disability, can CWE book our trip?",
    answer:
      "Yes, we can. We will work with you to provide the best and most appropriate resorts, accommodation and transportation choices.",
  },
  {
    question: "How do I make a hotel reservation?",
    answer:
      "You asked the right question and we are happy to help you. Reach out to one of our travel experts and we’ll guide you each step of the way. Click on the chat button at the bottom of the page and email us, send an email to info@caribbeanworldexplorer.com or call us at 1(854) 233-0993.",
  },
  {
    question: "Do you offer payment plans?",
    answer:
      "You may make a deposit on a reservation and make monthly payments until your bill is cleared and full payment is made. Talk to one of our travel specialists so that we can find a payment plan that suits you.",
  },
  {
    question: "What are your payment options?",
    answer:
      "You may do a bank wire transfer, bank deposit, Zelle or credit/debit card payment.",
  },
  {
    question: "How many rooms can I reserve in one booking?",
    answer:
      "You can reserve as many rooms as you’d like once the hotel that you’ve chosen has availability.",
  },
  {
    question:
      "What is the maximum bedding arrangement that can be made in one booking?",
    answer:
      "The maximum bedding arrangement depends on the room/rate selected. Standard rooms have 1 or 2 beds, but suites may offer additional bedding options, such as a pull-out couch.",
  },
  {
    question: "When will I receive my booking confirmation?",
    answer:
      "It can take us a couple days to process the booking. Your confirmation number will be sent to your email address within 2 – 5 business days of making your full or final payment.",
  },
  {
    question: "How do I cancel a booking?",
    answer:
      "We can only process cancellations when we have received written confirmation from you. You may send an email to billings@caribbeanworldexplorer.com.",
  },
  {
    question:
      "I booked a trip last week and now it’s on sale, can I have the difference refunded?",
    answer:
      "Unfortunately, we can’t refund the difference of a trip that is now on sale. This is because whenever you book a trip, you agree to the booking conditions associated with that trip. In order to book the trip at the new sale price, you would need to cancel the old reservation, forfeit any cancellation fees and re-pay your deposit to secure the discounted price. This may or may not work in your favor.",
  },
  {
    question:
      "How long will it take for me to receive a refund or get access to the money that was transferred to a future booking?",
    answer:
      "We try to process refunds as quickly as possible, but it may take 14 – 35 days from the day that the booking was cancelled. The time taken depends on your bank and external systems that are out of our control. If the funds aren’t back in your account within 35 days, please contact your bank.",
  },
  {
    question: "Is there an extra charge when paying by credit card?",
    answer:
      "A bank charge may be applied. However, in most instances there are no extra charges when paying for your trip with a credit or debit card, but there may be restrictions on which bank cards can be used depending on your location.",
  },
  {
    question:
      "I booked a trip with my friends. Can I pay my share of the deposit only?",
    answer:
      "Yes. You may reach out to one of our travel agents and they’ll send you a secure payment link for the amount you wish to pay.",
  },
  {
    question: "When is my final payment due?",
    answer:
      "Final payment may be due 14 days - 56 days before departure to avoid trip cancellation. Your payment due date can be found on your invoice. We’ll also email you at least a week before the due date as a reminder.",
  },
];

const FAQPage = () => {
  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <Container>
        <Typography variant="h4" gutterBottom marginY={5} color={"#165B76"}>
          Frequently Asked Questions
        </Typography>
        {faqData.map((faq, index) => (
          <Accordion key={index} >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" fontWeight={"bold"} fontSize={18} color={"#DB504A"}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"#165B76"}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </div>
  );
};

export default FAQPage;
