import { VacationDeal } from "./VacationDeals/VacationCard";
import { attractions } from "./VacationDeals/VacationCard";

export const vacationDeals: VacationDeal[] = [
  {
    id: "V1",
    title: "Secrets Cap Cana Resort & Spa",
    place: "Boulevard Zone Hotelena, Playa Juanillo, Punta Cana, Dominican Republic",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    pricing: "From $2197",
    rating: 5,
    image:
      "https://www.capcanaresortspa.com/images/gallery/gallery-2.jpg",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://secrets-cap-cana-resort-spa.booked.net/data/Photos/OriginalPhoto/12781/1278157/1278157846/Secrets-Cap-Cana-Resort-Spa-Adults-Only-Exterior.JPEG",
      "https://thumbcdn-3.hotelurbano.net/R-qsiVpkNnP6FAGYeY1-7NDw-uI=/900x600/center/middle/https://novo-hu.s3.amazonaws.com/reservas/ota/prod/hotel/2780/secrets-cap-cana-001_20190702151219.jpg",
      "https://www.capcanaresortspa.com/images/slides/slide-6.jpg"
    ],
    description: "Secrets Cap Cana Resort & Spa is a luxurious adults-only resort nestled in the stunning Cap Cana enclave of the Dominican Republic. This upscale tropical paradise offers an all-inclusive experience with lavish accommodations, gourmet dining options, and a wide range of activities. Guests can relax on the pristine beaches, enjoy swim-out suites with private pools, and unwind in the world-class spa. The resort also features championship golf, water sports, and romantic settings for weddings and honeymoons. With its elegant design and exceptional service, Secrets Cap Cana is an ideal destination for couples seeking a romantic and secluded getaway."
  },
  {
    id: "V2",
    title: "Sandals Ochi – COUPLES ONLY",
    place: "Main Street, Ocho Rios, Jamaica",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    pricing: "From $2056",
    rating: 5,
    image:
      "https://cdn.sandals.com/sandals/v13/images/EN/uploads/sgo_overview_007_c464a17528.jpg",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://images.trvl-media.com/lodging/1000000/610000/604200/604152/96096bf7.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://sunnyescapes.co.uk/wp-content/uploads/2019/10/download-34-1.jpg",
      "https://d3hk78fplavsbl.cloudfront.net/assets/common-prod/hotel/300/17266/17266-1-hotel_carousel_large.jpg?version=24"
    ],
    description: "Sandals Ochi is an exclusive, couples-only resort nestled in the lush landscapes of Jamaica. This romantic getaway offers luxurious accommodations, exquisite dining options, and an array of activities. Guests can relax on pristine beaches, enjoy water sports, or unwind in the spa. With its vibrant nightlife and serene hideaways, Sandals Ochi caters to both adventure-seekers and those looking for a tranquil escape. Experience the ultimate romantic retreat in a stunning tropical setting at Sandals Ochi, where love blossoms amidst breathtaking scenery and personalized service."

  },
  {
    id: "V3",
    title: "Moon Palace Jamaica",
    place: "Main Street, Ocho Rios, Jamaica",
    pricing: "From $2078",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    rating: 5,
    image:
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/339532861.jpg?k=f68b5f791520667fbed122e261e424e79ee0250513ac3e85dd4fb6bcd9a0c7bf&o=&hp=1",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://jamaica.moonpalace.com/ocean_view_suite_760edac8bd.jpg",
      "https://images.trvl-media.com/lodging/10000000/9030000/9022300/9022224/8fdb4a7d.jpg?impolicy=fcrop&w=1200&h=800&p=1&q=medium",
      "https://www.ca.kayak.com/rimg/himg/88/0b/c1/ice-62936-73817041_3XL-531910.jpg?width=720&height=576&crop=true"
    ],
    description: "Moon Palace Jamaica is a luxurious, all-inclusive resort nestled on the stunning shores of Ocho Rios, Jamaica. This tropical paradise offers guests a blend of natural beauty and top-notch amenities. With spacious accommodations, gourmet dining options, a pristine beach, and a range of activities, it's an ideal destination for both relaxation and adventure. Moon Palace Jamaica boasts a water park, multiple pools, a spa, and numerous water sports to keep guests entertained. Whether you're seeking a romantic getaway or a family-friendly retreat, this resort provides a memorable Jamaican experience in a breathtaking setting."
  },
  {
    id: "V4",
    title: "Breezes Resort Bahamas",
    place: "W Bay St, Cable Beach, Nasaau",
    pricing: "From $1617",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    rating: 5,
    image:
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/61956061.jpg?k=585ba33a341a9faa8e984fde9e15925a4f8336bed13575cb5b45977f5334e0a5&o=&hp=1",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://www.jetbluevacations.com/cdn-cgi/image/f=auto,width=804,height=1080/https://www.jetbluevacations.com/images_w/jetblue/suppliers/image_url_26430.jpg",
      "https://www.jaital.com/images/tours/image1_Tive_3408.jpg",
      "https://assets.sunwingtravelgroup.com/image/upload/f_auto,q_auto/sunwing-prod/HotelImages/NAS8HB/16x9/004.jpg"
    ],
    description: "Breezes Resort Bahamas is a tropical paradise nestled on the picturesque Cable Beach in Nassau, Bahamas. This all-inclusive beachfront resort offers guests an idyllic getaway with pristine white sands, crystal-clear waters, and lush gardens. It features a range of amenities including multiple dining options, water sports, a spa, and nightly entertainment. Breezes Resort Bahamas is known for its vibrant atmosphere and welcoming staff, making it an ideal destination for couples, families, and friends looking to relax, unwind, and create lasting memories in a stunning Caribbean setting."
  },
  {
    id: "V5",
    title: "Alexandra Resort",
    place: "Princess Dr, Providenciales, Turks and Caicos",
    pricing: "From $3596",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    rating: 4,
    image:
      "https://www.turksandcaicossir.com/localimagereader.ashx?imageurl=siteresources%2Fmy%20folder%2Farticle%2520photos_islands%2Fnew%2520article%2520photos%2Falexandra%2520resort.jpg",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/464580907.jpg?k=c3c592774744b8c83b8ff31d0b99968b15d17437d1c5d262f05c80ec66af9000&o=&hp=1",
      "https://www.alexandraresort.com/wp-content/uploads/2023/05/Alexandra-All-Inclusive-Turks-Resort-Dining-2.jpg",
      "https://www.onlyturksandcaicos.com/alexandra/img/fullsize/013-the-alexandra-resort-spa-2-lg[1].jpg"
    ],
    description: "Alexandra Resort is a luxurious beachfront retreat located on the stunning Grace Bay Beach in Providenciales, Turks and Caicos Islands. This upscale resort offers guests a blend of modern comforts and natural beauty, with spacious and elegantly appointed suites, all-inclusive dining options, and a range of amenities. Guests can relax by the pristine turquoise waters, indulge in water sports, savor delicious cuisine at on-site restaurants, and unwind at the spa. With its breathtaking views and warm hospitality, Alexandra Resort provides an ideal tropical getaway for those seeking relaxation and adventure in the Caribbean paradise."
  },
  {
    id: "V6",
    title: "Turquoize at Hyatt Ziva Cancun",
    place: "Blvd. Kukulcan, Manzana 51, Lote. 7, Cancun, Mexico",
    pricing: "From $3273",
    accommodation: "4 Nights & 5 Days",
    adults: "2 Adults",
    rating: 4,
    image:
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/75360360.jpg?k=1b6f83e3fa33869450495ed3f8868b3e6bb1a461dcf9d8b1c1529f13791c3921&o=&hp=1",
    attractions: [
      attractions.adult,
      attractions.allInclusive,
      attractions.bus,
      attractions.car,
      attractions.rooms,
    ],
    morePhotos: [
      "https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2016/07/28/1144/Hyatt-Ziva-Cancun-P276-Turquoize-Ocean-Front-Master-King-Living-Room.jpg/Hyatt-Ziva-Cancun-P276-Turquoize-Ocean-Front-Master-King-Living-Room.16x9.jpg?imwidth=1280",
      "https://tripfind.com/wp-content/uploads/2020/12/hyatt_ziva_cancun-4055.jpg",
      "https://www.turquoizecancun.com/images/slides/slide-17.jpg"
    ],
    description: "Turquoize at Hyatt Ziva Cancun is an exclusive, adults-only experience nestled within the larger Hyatt Ziva Cancun resort. It offers a luxurious and romantic escape in a stunning beachfront setting. Turquoize guests enjoy access to private infinity pools and exclusive dining options, creating an intimate atmosphere perfect for couples. The spacious suites feature breathtaking ocean views, modern amenities, and direct beach access. With personalized butler service and endless opportunities for relaxation and adventure, Turquoize at Hyatt Ziva Cancun promises a truly memorable and indulgent getaway for adults seeking a refined and tranquil Cancun retreat."
  },
];

export const PACKAGES_INFO = {
  package_image:
    "https://images.pexels.com/photos/2434578/pexels-photo-2434578.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  package_title: "Vacation Packages",
  package_description:
    "Vacation Packages at CWE offer convenient and cost-effective trip planning, including accommodations, transportation, activities, and tours. We cater to diverse interests and travel needs, whether it's a family getaway, romantic honeymoon, adventure tour, babymoon, cultural exploration, or adults-only escape. Our flexible approach and expert team ensure satisfaction, whether you choose a renowned resort or hidden gem. Explore your destination with activities like casinos, golfing, and spa treatments. Reach out for a tailored package that adds comfort and style to your adventure. Experience your upcoming journey the CWE way – stress-free and enjoyable!",
  package_actionText: "Start Planning",
  package_route: "",
};
