import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { vacationDeals } from "../VacationsPage/constants";
import { cruiseDeals } from "../CruisesPage/constants";
import Breadcrumb from "../../components/Breadcrumbs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import { VacationDeal } from "../VacationsPage/VacationDeals/VacationCard";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DomainIcon from "@mui/icons-material/Domain";
import DiningIcon from "@mui/icons-material/Dining";
import AccessibleForwardIcon from "@mui/icons-material/AccessibleForward";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ModalCarousel from "../../components/MorePhotosModal";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import {
  RootState,
  saveVacationDeal,
  unsaveVacationDeal,
  initDealsFromCookies,
} from "../../redux/store";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Deal() {
  // Get the current location
  const location = useLocation();
  const allDeals = [...vacationDeals, ...cruiseDeals];
  const [deal, setDeal] = useState<VacationDeal>();
  const dispatch = useDispatch();
  const isSaved = useSelector((state: RootState) =>
    state.savedDeals.vacationDeals.includes(deal?.id || "")
  );

  // Access the query parameters
  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");
  const id = queryParams.get("id");

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlesaveVacationDeal = (dealId: string) => {
    dispatch(saveVacationDeal(dealId));
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const newSavedDeals = [...parsedDeals.vacationDeals, dealId];
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: newSavedDeals,
          cruiseDeals: parsedDeals.cruiseDeals,
        }),
        { expires: 365 }
      );
    } else {
      Cookies.set(
        "savedDeals",
        JSON.stringify({ vacationDeals: [dealId], cruiseDeals: [] }),
        { expires: 365 }
      );
    }
  };

  const handleUnsaveVacationDeal = (dealId: string) => {
    dispatch(unsaveVacationDeal(dealId));
    console.log();
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const filteredDeals = parsedDeals.vacationDeals.filter(
        (id) => id !== dealId
      );
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: filteredDeals,
          cruiseDeals: parsedDeals.cruiseDeals,
        }),
        { expires: 365 }
      );
    }
  };

  const handleSaveClick = () => {
    if (isSaved && deal) {
      console.log("Unsaved");
      handleUnsaveVacationDeal(deal.id);
    } else if (deal) {
      console.log("saved");
      handlesaveVacationDeal(deal.id);
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: deal?.title,
          text: `Check out this amazing deal: ${deal?.title}`,
          url: window.location.href,
        });
        console.log("Deal shared successfully");
      } catch (error) {
        console.error("Error sharing deal:", error);
      }
    } else {
      console.log("Web Share API not supported");
      // Fallback for browsers that do not support Web Share API
      // You can implement your custom sharing logic here
    }
  };

  const handleInquire = () => {
    const recipient = "info@caribbeanworldexplorer.com";
    const subject = deal?.title || "";
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
  };

  useEffect(() => {
    if (id) {
      // Find the deal with the matching ID
      const matchingDeal = allDeals.find((deal) => deal.id === id);

      // Set the deal in state if found
      if (matchingDeal) {
        setDeal(matchingDeal);
      }
    }
  });

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginY={15}
      height={"100%"} // Center content vertically and horizontally
    >
      <Breadcrumb showSearch={false} />
      <Container maxWidth="lg">
        <Grid container spacing={2} marginTop={10}>
          {/* Left container (70%) */}
          <Grid item xs={12} md={7}>
            <Box position="relative">
              <img
                src={deal?.image}
                alt="Vacation"
                style={{ width: "100%", height: "auto" }}
              />
              {/* Button to open modal */}
              {deal && deal.morePhotos.length > 0 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                  style={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    backgroundColor: "#DB504A",
                  }}
                >
                  More Photos
                </Button>
              )}
            </Box>
            <Divider
              orientation="horizontal"
              sx={{ marginTop: 5, marginBottom: 5 }}
            />
            {/* Vacation Highlights */}
            <Box mt={3}>
              <Typography variant="h5">Vacation Highlights</Typography>
              <ListItem>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="People" secondary={deal?.adults || "As many booked"} />
              </ListItem>
              {/* Hotel Icon and Number of Nights */}
              <ListItem>
                <ListItemIcon>
                  <HotelIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Accommodation"
                  secondary={deal?.accommodation || "7+ night cruise aboard " + deal?.title}
                />
              </ListItem>
            </Box>

            <Divider
              orientation="horizontal"
              sx={{ marginTop: 5, marginBottom: 5 }}
            />

            {/* Resort History */}
            <Box mt={3}>
              <Typography variant="h5" marginBottom={5}>
                {deal?.title}
              </Typography>
              <Typography variant="body1">{deal?.description}</Typography>
            </Box>

            <Divider
              orientation="horizontal"
              sx={{ marginTop: 5, marginBottom: 5 }}
            />

            {/* Amenities */}
            {deal && deal.id.startsWith("V") && (
              <Box mt={5}>
              <Typography variant="h5" marginBottom={5}>
                Amenities
              </Typography>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="property-features-content"
                  id="property-features-header"
                >
                  <ListItemIcon>
                    {/* Add the icon for property & features */}
                    <DomainIcon />
                  </ListItemIcon>
                  <Typography>Property & Features</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Dropdown content for property & features */}
                  <ul>
                    <li>Spacious rooms</li>
                    <li>Swimming pool</li>
                    <li>Fitness center</li>
                    <li>Conference rooms</li>
                    <li>Parking</li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="dining-entertainment-content"
                  id="dining-entertainment-header"
                >
                  <ListItemIcon>
                    {/* Add the icon for dining & entertainment */}
                    <DiningIcon />
                  </ListItemIcon>
                  <Typography>Dining & Entertainment</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Dropdown content for dining & entertainment */}
                  <ul>
                    <li>Restaurant</li>
                    <li>Bar</li>
                    <li>Room service</li>
                    <li>Lounge area</li>
                    <li>Nightclub</li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="disabled-facilities-content"
                  id="disabled-facilities-header"
                >
                  <ListItemIcon>
                    {/* Add the icon for disabled facilities */}
                    <AccessibleForwardIcon />
                  </ListItemIcon>
                  <Typography>Disabled Facilities</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Dropdown content for disabled facilities */}
                  <ul>
                    <li>Wheelchair ramps</li>
                    <li>Accessible rooms</li>
                    <li>Braille signage</li>
                    <li>Accessible parking</li>
                    <li>Elevators with Braille buttons</li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="family-facilities-content"
                  id="family-facilities-header"
                >
                  <ListItemIcon>
                    {/* Add the icon for family facilities */}
                    <FamilyRestroomIcon />
                  </ListItemIcon>
                  <Typography>Family Facilities</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Dropdown content for family facilities */}
                  <ul>
                    <li>Children's play area</li>
                    <li>Babysitting services</li>
                    <li>Family-friendly rooms</li>
                    <li>High chairs in the restaurant</li>
                    <li>Outdoor picnic area</li>
                  </ul>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="nearby-places-content"
                  id="nearby-places-header"
                >
                  <ListItemIcon>
                    {/* Add the icon for nearby places */}
                    <LocationOnIcon />
                  </ListItemIcon>
                  <Typography>Nearby Places</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Dropdown content for nearby places */}
                  <ul>
                    <li>Beach</li>
                    <li>Shopping mall</li>
                    <li>Tourist attractions</li>
                    <li>Restaurants</li>
                    <li>Parks</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
            </Box>
            )}
          </Grid>

          {/* Right container (30%) */}
          <Grid item xs={12} md={5} style={{ position: "sticky", right: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
                // height: 500,
                border: "0.1px solid lightgray",
                borderWidth: 0.1,
              }}
            >
              <Typography variant="h4" textAlign={"center"} marginBottom={2}>
                {deal?.title}
              </Typography>
              <Typography variant="body1" textAlign={"center"} marginBottom={2}>
                {deal?.place}
              </Typography>
              {deal?.id.startsWith("V") && (
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  marginBottom={2}
                >
                  {deal?.accommodation} for {deal?.adults} | All Inclusive
                </Typography>
              )}
              <Typography variant="body1" textAlign={"center"} marginBottom={5}>
                {deal?.pricing}
              </Typography>
              {deal?.rating && (
                <Rating
                  value={deal.rating}
                  sx={{ marginBottom: 10, color: "#DB504A" }}
                  readOnly
                />
              )}

              <Typography
                fontSize={"0.6rem"}
                color={"gray"}
                textAlign={"center"}
              >
                Call 1-854-233-0993 to connect with a travel expert.
              </Typography>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                sx={{
                  marginTop: 5,
                  backgroundColor: "#DB504A",
                  marginBottom: 5,
                  "&:hover": {
                    backgroundColor: "#DB504A"
                  }
                }}
                onClick={handleInquire}
              >
                Inquire
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <div style={{ border: "1px solid lightgray", padding: "5px", width: "40%", textAlign: "center" }}>
                  <IconButton
                    onClick={(event) => {
                      handleSaveClick();
                    }}
                  >
                    {isSaved ? (
                      <FavoriteIcon style={{ color: "#DB504A" }} />
                    ) : (
                      <FavoriteBorderIcon style={{ color: "#DB504A" }} />
                    )}
                    {"  "}
                    <Typography
                      variant="body2"
                      style={{ color: isSaved ? "#DB504A" : "" }}
                    >
                      {isSaved ? "Saved" : "Save"}
                    </Typography>
                  </IconButton>
                </div>

                <div style={{ border: "1px solid lightgray", padding: "5px", width: "40%", textAlign: "center" }}>
                  {/* Add the Share button here */}
                  <IconButton
                    onClick={(event) => {
                      handleShare();
                    }}
                  >
                    <ShareIcon style={{ color: "#0074CC" }} />
                    {"  "}
                    <Typography
                      variant="body2"
                    >
                      Share
                    </Typography>
                  </IconButton>
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>

        {/* Modal for More Photos */}
        <ModalCarousel
          images={deal?.morePhotos || []}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      </Container>
    </Box>
  );
}
