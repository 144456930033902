import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  createTheme,
  Box,
  Container,
} from "@mui/material";
import { destinations } from "./constants";

const theme = createTheme();

const useStyles = {
  card: {
    position: "relative",
    height: "250px", // Adjust the height as needed
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&:hover::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    width: "100%",
  },
  cardContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    textAlign: "center",
  },
};

export default function TropicalDestinations() {
  return (
    <Container
      sx={{ marginTop: 10 }}
    >
      <Typography
        variant="h4"
        sx={{ color: "#165B76", textAlign: "center", marginBottom: 4 }}
        paddingX={{ sm: 5, xs: 5 }}
      >
        Explore These Wonders
      </Typography>
      <Typography
        variant="body1"
        sx={{ textAlign: "center", marginBottom: 4 }}
        paddingX={{ lg: 30, md: 30, sm: 5, xs: 2 }}
      >
        One world. Many options. If you want to relax and recoup, there’s a
        destination for you. If you want to celebrate, there’s a destination for
        you. Whatever the occasion is, there’s a destination for you. Connect
        with us so that we can assist you. Here are a few of our top picks.
      </Typography>
      <Grid container spacing={2} marginBottom={10}>
        {destinations.map((resort, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <Card
              sx={useStyles.card}
              style={{ backgroundImage: `url(${resort.image})` }}
            >
              <CardContent sx={useStyles.cardContent}>
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  style={{ textShadow: "2px 2px 4px rgba(0,0,0,0.5)" }}
                >
                  {resort.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
