import React from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material";

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(10, 20),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10, 20),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(10, 5),
    },
    width: "100%",
  },
}));

const GroupTravelInfo = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography
        variant="h5"
        gutterBottom
        textAlign={"center"}
        fontWeight={"bold"}
        marginBottom={5}
      >
        Book 10+ Hotel Rooms or 16+ Cruise Cabins and Save
      </Typography>
      {/* <Typography variant="h4" textAlign={"center"} gutterBottom>
        Group Travel Planning Made Easy
      </Typography> */}
      <Typography variant="body2" textAlign={"center"} paddingX={"auto"}>
        Rest assured that your group trip is in capable hands. Our team of
        travel experts will identify the ideal destination and manage every
        aspect, from securing accommodations to catering the ideal itinerary.
      </Typography>
    </Container>
  );
};

export default GroupTravelInfo;
