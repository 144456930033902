import * as React from "react";
import { ColorPaletteProp } from "@mui/joy/styles";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListSubheader from "@mui/joy/ListSubheader";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemButton from "@mui/joy/ListItemButton";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import InstagramIconRoundedIcon from "@mui/icons-material/Instagram";
import YouTubeIconRounded from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

export default function ColorInversionFooter() {
  const [color, setColor] = React.useState<ColorPaletteProp>("primary");
  return (
    <Sheet
      variant="solid"
      color={color}
      invertedColors
      sx={{
        ...(color !== "neutral" && {
          bgcolor: `${color}.800`,
        }),
        flexGrow: 1,
        p: 2,
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", null, null, "row"], // Use column on small screens, row on large screens
          alignItems: "center",
          justifyContent: ["center", null, null, "space-between"], // Center on small screens, space-between on large screens
          gap: 2,
        }}
      >
        {/* Icons */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            width: ["100%", null, null, "50%"]
          }}
        >
          <a
            href="https://www.youtube.com/@CaribbeanWorldExplorer/featured"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton variant="plain">
              <img
                src={require("../../assets/youtube.png")}
                style={{ width: 24, color: "white" }}
              />
            </IconButton>
          </a>
          <a
            href="https://instagram.com/caribbeanworldexplorer?igshid=NGVhN2U2NjQ0Yg=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton variant="plain">
              <img
                src={require("../../assets/instagram.png")}
                style={{ width: 24, color: "white" }}
              />
            </IconButton>
          </a>
          <a
            href="https://www.tiktok.com/@caribbeanworldexplorer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton variant="plain">
              <img
                src={require("../../assets/tiktok.png")}
                style={{ width: 24, color: "white" }}
              />
            </IconButton>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=61552674022441"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton variant="plain">
              <img
                src={require("../../assets/facebook.png")}
                style={{ width: 24, color: "white" }}
                alt="Facebook icons created by Pixel perfect - Flaticon"
              />
            </IconButton>
          </a>
        </Box>

        {/* Copyright Text */}
        <Typography width={["100%", null, null, "50%"]} textAlign={["center", null, null, "right"]}>
          &copy; {new Date().getFullYear()} Caribbean World Explorer Inc.
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "row" },
          alignItems: { md: "flex-start" },
          justifyContent: "space-around",
          gap: 2,
        }}
      >
        <a
          href="/"
          style={{
            margin: 4,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            width: 150,
            borderRadius: "50%",
            backgroundColor: "white",
          }}
        >
          <img src={require("../../assets/LOGO.png")} width={"100%"} />
        </a>

        <Grid container spacing={2} md={4} xs={12}>
          <Grid item xs={12} md={6}>
            <List>
              <ListSubheader>Sitemap</ListSubheader>
              <ListItem>
                <Link to="/vacations" style={{ textDecoration: "none" }}>
                  <ListItemButton>Vacations</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/destinations" style={{ textDecoration: "none" }}>
                  <ListItemButton>Destinations</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/cruises" style={{ textDecoration: "none" }}>
                  <ListItemButton>Cruises</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/group-travel" style={{ textDecoration: "none" }}>
                  <ListItemButton>Group Travel</ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <List>
              <ListItem>
                <Link to="/tours" style={{ textDecoration: "none" }}>
                  <ListItemButton>Tours</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/wanderlist" style={{ textDecoration: "none" }}>
                  <ListItemButton>Saved Deals</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to="/terms-and-conditions"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton>Terms And Conditions</ListItemButton>
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/faq" style={{ textDecoration: "none" }}>
                  <ListItemButton>FAQ</ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Grid>
        </Grid>

        <Typography
          sx={{ width: { xs: "100%", sm: "100%", md: "30%", lg: "30%" } }}
        >
          Disclaimer: Please refer to our{" "}
          <Link to="/terms-and-conditions" style={{ textDecoration: "none" }}>
            terms and conditions
          </Link>
          <a
            href="https://www.flaticon.com/free-icons/facebook"
            title="facebook icons"
          >
            {" "}
            Icons created by Pixel perfect - Flaticon
          </a>
        </Typography>
      </Box>
    </Sheet>
  );
}
