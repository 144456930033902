import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

const destinations = [
  {
    title: "Caribbean",
    description:
      "This includes Jamaica, Bahamas, Aruba, Turks and Caicos, US Virgin Islands and many more.",
  },
  {
    title: "North America",
    description: "This includes the United States of America, Mexico, Canada and many more.",
  },
  {
    title: "Europe",
    description:
      "This includes England, France, Greece, Italy and many more.",
  },
  {
    title: "Africa",
    description:
      "This includes Egypt, Morocco, South Africa, Liberia and much more.",
  },
  {
    title: "Asia",
    description:
      "This includes Cambodia, China, Hong Kong, Japan, Singapore, Thailand, Indonesia and many more.",
  },
  {
    title: "Australia",
    description:
      "This includes Sydney, Great Barrier Reef and many more.",
  },
  {
    title: "Middle East",
    description:
      "This includes Arabian desert and many more.",
  },
];

export default function TopDestinations() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        margin: 0,
        marginTop: 10,
        marginBottom: 10,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto", // Set to 100vh for full viewport height
        width: "100%",
      }}
    >
      {/* <Container maxWidth={false} sx={{margin: 0, padding: 0}}> */}
      <Card
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "stretch",
          minHeight: "100vh", // Set card height to 100% of the viewport
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: 0,
          width: "100%",
          borderRadius: 0
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: isSmallScreen ? "100%" : "50%", // Image takes 50% of the card width
            height: "auto",
            borderRadius: 0,
          }}
          image={
            "https://pics.tui.com/pics/pics1600x1200/tui/4/454263c6-b6d9-4673-be95-78e7890c7782.jpg"
          }
          alt="Card Image"
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically
            width: isSmallScreen ? "100%" : "50%", // Content takes 50% of the card width
            backgroundColor: "#165B76", // Set the background color for the content
            color: "white", // Set text color to white
            padding: isSmallScreen ? 2: 10,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{
              marginBottom: "8%", // Space below the title
              fontWeight: "bold",
              fontSize: {
                xs: "1.5rem",
                sm: "1.8rem",
                md: "1.9rem",
                lg: "2.1rem",
              },
            }}
          >
            Top Destinations In The World
          </Typography>
          {destinations.map((destination, index) => (
            <div key={index}>
              <Typography
                variant="h6"
                fontSize={"1rem"}
                color={"#DB504A"}
                fontWeight={"bold"}
                sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
              >
                {destination.title}
                <Typography
                  variant="body1"
                  fontSize={"1rem"}
                  color={"white"}
                >
                  {destination.description}
                </Typography>
              </Typography>
            </div>
          ))}
        </CardContent>
      </Card>
      {/* </Container> */}
    </Box>
  );
}
