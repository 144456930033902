import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Box,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, NavLink, useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge"; // Import the Badge component
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseIcon from "@mui/icons-material/Close";
import "./Navbar.css"; // Import a CSS file for custom styles
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import TravelExpertsModal from "../TravelExpertsModal";

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const savedDeals = useSelector((state: RootState) => state.savedDeals);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Function to check screen width and set isSmallScreen accordingly
    const checkScreenWidth = () => {
      setIsSmallScreen(window.innerWidth < 1080); // Adjust the threshold as needed
    };

    // Initial check
    checkScreenWidth();

    // Add a window resize listener to update isSmallScreen
    window.addEventListener("resize", checkScreenWidth);

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const phoneNumber = "+18542330993"; // Replace with your phone number

  const handlePhoneCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        backgroundColor: "beige",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          backgroundColor: "rgb(255, 255, 255)",
        }}
      >
        {isSmallScreen ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <a
              href="/"
              style={{
                margin: 4,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                width: 80,
                borderRadius: "50%",
              }}
            >
              <img src={require("../../assets/LOGO.png")} width={80} />
            </a>
            <IconButton
              edge="start"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{
                color: "#DB504A",
                "&:hover": { backgroundColor: "transparent" },
                "&:focus": { outline: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
          </div>
        ) : (
          <a
            href="/"
            style={{
              margin: 4,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              width: 80,
              borderRadius: "50%",
            }}
          >
            <img src={require("../../assets/LOGO.png")} width={"100%"} />
          </a>
        )}

        {!isSmallScreen && (
          <>
            <List
              component="nav"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "space-between",
                width: "60%",
              }}
            >
              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/vacations"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Vacations
                </NavLink>
              </ListItem>

              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/destinations"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Destinations
                </NavLink>
              </ListItem>

              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/cruises"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Cruises
                </NavLink>
              </ListItem>

              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/group-travel"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Group Travel
                </NavLink>
              </ListItem>

              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/tours"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Tours
                </NavLink>
              </ListItem>

              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/contact-us"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  Contact Us
                </NavLink>
              </ListItem>
              <ListItem sx={{ width: "auto" }}>
                <NavLink
                  to={"/faq"}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                    fontWeight: 500,
                    fontSize: 14,
                  }}
                  className={({ isActive, isPending }) =>
                    isPending
                      ? "pending nav-tem"
                      : isActive
                      ? "active-link nav-item"
                      : "nav-item"
                  }
                >
                  FAQ
                </NavLink>
              </ListItem>
            </List>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "30%",
              }}
            >
              {/* Phone icon for calling */}
              <Tooltip title="Call Us">
                <IconButton
                  color="primary"
                  aria-label="phone"
                  sx={{
                    color: "#165B76",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#DB504A",
                    },
                    "&:focus": { outline: "none" },
                  }}
                  onClick={() => {
                    handlePhoneCall();
                  }}
                >
                  <PhoneIcon />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 300, fontSize: 14 }}
                  >
                    +1 (854) 233-0993
                  </Typography>
                </IconButton>
              </Tooltip>

              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "#165B76", borderWidth: "1px" }}
              />

              <IconButton
                color="primary"
                aria-label="person-search"
                sx={{
                  color: "#165B76",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#DB504A",
                  },
                  "&:focus": { outline: "none" },
                }}
                onClick={() => {
                  handleOpenModal();
                }}
              >
                <Tooltip title="Find Expert">
                  <PersonSearchIcon />
                </Tooltip>
              </IconButton>

              <Link to="/stores">
                {/* Location icon for map */}
                <IconButton
                  color="primary"
                  aria-label="location"
                  sx={{
                    color: "#165B76",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#DB504A",
                    },
                    "&:focus": { outline: "none" },
                  }}
                >
                  <Tooltip title="Find Us">
                    <LocationOnIcon />
                  </Tooltip>
                </IconButton>
              </Link>

              {/* Favorite icon for saved deals */}
              <Link to="/wanderlist">
                {" "}
                {/* Link to the SavedDealsPage */}
                <IconButton
                  color="primary"
                  aria-label="favorite"
                  sx={{
                    color: "#165B76",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "#DB504A",
                    },
                    "&:focus": { outline: "none" },
                  }}
                >
                  <Tooltip title="Saved Deals" placement="top">
                    <Badge
                      badgeContent={
                        savedDeals.vacationDeals.length +
                        savedDeals.cruiseDeals.length
                      }
                      color="success"
                    >
                      <FavoriteIcon />
                    </Badge>
                  </Tooltip>
                </IconButton>
              </Link>
            </div>
          </>
        )}

        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer}
          onOpen={toggleDrawer}
          PaperProps={{
            sx: {
              backgroundColor: "white",
              width: "65%",
              paddingLeft: 2,
              paddingRight: 2,
              paddingTop: 2,
            },
          }}
        >
          {/* Close Icon */}
          <IconButton
            color="error"
            aria-label="close"
            sx={{ position: "absolute", top: 0, right: 0, zIndex: 1 }}
            onClick={toggleDrawer}
          >
            <CloseIcon />
          </IconButton>
          <Typography style={{ color: "#DB504A", fontWeight: "bold" }}>
            Travel
          </Typography>
          <List sx={{ padding: 0 }}>
            <ListItem
              component={NavLink}
              to="/vacations"
              sx={{ marginBottom: 1, padding: 0 }}
            >
              <ListItemText
                primary="Vacations"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/destinations"
              sx={{ marginBottom: 1, padding: 0 }}
            >
              <ListItemText
                primary="Destinations"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/cruises"
              sx={{ marginBottom: 1, padding: 0 }}
            >
              <ListItemText
                primary="Cruises"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/group-travel"
              sx={{ marginBottom: 1, padding: 0 }}
            >
              <ListItemText
                primary="Group Travel"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/tours"
              sx={{ marginBottom: 1, padding: 0 }}
            >
              <ListItemText
                primary="Tours"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/contact-us"
              sx={{ margin: 0, padding: 0 }}
            >
              <ListItemText
                primary="Contact Us"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
          </List>
          <br />

          <Typography style={{ color: "#DB504A", fontWeight: "bold" }}>
            Saved Deals
          </Typography>
          <List sx={{ padding: 0 }}>
            <ListItem
              component={NavLink}
              to="/wanderlist"
              sx={{ margin: 0, padding: 0 }}
            >
              <ListItemText
                primary={`Wanderlist (${
                  savedDeals.vacationDeals.length +
                  savedDeals.cruiseDeals.length
                })`}
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
          </List>
          <br />

          <Typography style={{ color: "#DB504A", fontWeight: "bold" }}>
            Terms and Information
          </Typography>
          <List sx={{ padding: 0 }}>
            <ListItem
              component={NavLink}
              to="/faq"
              sx={{ margin: 0, padding: 0 }}
            >
              <ListItemText
                primary="FAQ"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
            <ListItem
              component={NavLink}
              to="/terms-and-conditions"
              sx={{ margin: 0, padding: 0 }}
            >
              <ListItemText
                primary="Terms and Conditions"
                sx={{ color: "#165B76", margin: 0, padding: 0 }}
                className="nav-item"
              />
            </ListItem>
          </List>
          <br />

          <Typography style={{ color: "#DB504A", fontWeight: "bold" }}>
            Contact Us
          </Typography>

          <IconButton
            color="primary"
            aria-label="phone"
            sx={{
              display: "flex",
              alignSelf: "flex-start",
              color: "#165B76",
              "&:hover": { backgroundColor: "transparent", color: "#DB504A" },
              "&:focus": { outline: "none" },
              margin: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 1,
            }}
            onClick={() => {
              handlePhoneCall();
            }}
          >
            <PhoneIcon sx={{ marginRight: 1 }} />
            <Typography>Call Us</Typography>
          </IconButton>

          <IconButton
            color="primary"
            aria-label="person-search"
            sx={{
              display: "flex",
              alignSelf: "flex-start",
              color: "#165B76",
              "&:hover": { backgroundColor: "transparent", color: "#DB504A" },
              "&:focus": { outline: "none" },
              margin: 0,
              paddingLeft: 0,
              paddingTop: 0,
              paddingBottom: 1,
            }}
            onClick={() => {
              handleOpenModal();
            }}
          >
            <PersonSearchIcon sx={{ marginRight: 1 }} />
            <Typography>Locate an Expert</Typography>
          </IconButton>

          {/* Location icon for map */}
          <Link to={"/stores"} style={{ textDecoration: "none" }}>
            <IconButton
              color="primary"
              aria-label="location"
              sx={{
                display: "flex",
                alignSelf: "flex-start",
                color: "#165B76",
                "&:hover": { backgroundColor: "transparent", color: "#DB504A" },
                "&:focus": { outline: "none" },
                margin: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 1,
              }}
              onClick={() => {
                // Add code to navigate to the map
              }}
            >
              <LocationOnIcon sx={{ marginRight: 1 }} />
              <Typography>Find Us</Typography>
            </IconButton>
          </Link>
        </SwipeableDrawer>
      </Toolbar>
      {/* Render the TravelExpertsModal component */}
      <TravelExpertsModal open={isModalOpen} onClose={handleCloseModal} />
    </AppBar>
  );
}

export default Navbar;
