import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

const image =
  "https://www.travelandleisure.com/thmb/hpdlpJilAPiqDWIhOS_hf3r8uQI=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/pigeon-point-tobago-CARIBBEACHES0721-dae12455e4ef49378f2386dd52f0c7fc.jpg";
const title = "Gems of the Earth";
const description =
  "Choose from a multitude of destinations across the globe. Whether you desire pristine beaches, crystal-clear waters, lush forests, and welcoming locals in the Caribbean, or you're drawn to the rich tapestry of countries, cultures, history, and culinary delights in Europe, we will craft a customized vacation that aligns with your preferences. Whether you're seeking an adventure in Mexico with its ancient ruins, culture, cuisine, and colonial architecture, or a tropical paradise in Hawaii with world-class beaches, pristine rainforests, and active volcanoes, you can select any destination worldwide. Our team of travel experts is poised to plan and book your journey to your chosen destination.";
const actionText = "Start Planning";
const route = "";

export default function DestinationsHero() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleConnect = () => {
    const subject = `Destinations - Start Planning`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <Container sx={{ marginTop: isSmallScreen ? 5 : 10 }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "stretch", // Change to "stretch"
          minHeight: "200px",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: isSmallScreen ? "100%" : "60%",
            height: 650, // Change to "auto"
            marginBottom: isSmallScreen ? 2 : 0,
            marginRight: !isSmallScreen ? 3 : 0,
          }}
          image={image}
          alt="Card Image"
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems:  isSmallScreen ? "center" : "flex-start", // Center on small screens
            width: isSmallScreen ? "100%" : "40%",
            height: "100%",
            maxHeight: "100%",
            paddingLeft: isSmallScreen ? 0 : "auto"
          }}
        >
          <Typography
            variant="body1"
            component="div"
            width={"100%"}
            textAlign={"left"}
            sx={{
              marginBottom: "5%", // Space below the title
              color: "black",
              fontWeight: "bold",
              fontSize: {
                xs: "1.5rem",
                sm: "1.8rem",
                md: "1.9rem",
                lg: "2.5rem",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "5%", // Space below the description
              width: "fit-content",
              color: "black",
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.1rem",
                lg: "1.2rem",
              },
            }}
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#DB504A",
              textTransform: "none",
              width: "fit-content",
              height: {
                xs: 50,
              },
              fontSize: {
                xs: "0.8rem",
                lg: "1rem",
              },
              "&:hover": {
                backgroundColor: "#165B76", // Change the background color on hover
                color: "white",
              },
            }}
            onClick={handleConnect}
          >
            {actionText}
          </Button>
        </CardContent>
      </Card>
      {/* </Container> */}
    </Container>
  );
}
