import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Container, createTheme, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  heading: {
    color: "#165B76", 
    textAlign: "center", 
    marginBottom: theme.spacing(8)
  },
  card: {
    position: "relative",
    cursor: "mouse",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:hover::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
  },
  cardText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
}));

const destinations = [
  {
    name: "Caribbean",
    image:
      "https://www.royalcaribbean.com/blog/wp-content/uploads/2022/02/RCI_PDC_Nic_Morely_Coco_Cay_night_fireworks_DJI_0578_RET.jpg",
  },
  {
    name: "North America",
    image:
      "https://qtxasset.com/quartz/qcloud1/media/image/EXPLORA%20I_Exterior%20View_13_compressed.jpg?VersionId=t7mnrpwtrk4OBgCNala2scL7i7gdFVlN",
  },
  {
    name: "Europe",
    image:
      "https://www.travelmanagers.com.au/wp-content/uploads/2021/10/Riviera-Dubrovnik-750x500.jpg",
  },
  {
    name: "Central America",
    image:
      "https://cleancruising.imgix.net/destination/panama-and-central-america_gallery1.jpg?auto=format&w=960",
  },
  {
    name: "Mediterranean",
    image:
      "https://media.shermanstravel.com/Advice/952x460_cruiseinocean_istock.jpg",
  },
];

function PopularCruiseDestinations() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const classes = useStyles();

  return (
    <Container >
      <Typography variant="h4" className={classes.heading} marginBottom={8}>
        Popular Cruise Destinations
      </Typography>
      <Grid container spacing={2}>
        {destinations.map((destination, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={index < 2 ? 6 : 4}
            lg={index < 2 ? 6 : 4}
            xl={index < 2 ? 6 : 4}
            key={index}
          >
            <Card className={classes.card}>
              <CardMedia
                component="img"
                alt={destination.name}
                height= {!isSmallScreen && index < 2 ? "400" : "200"}
                image={destination.image}
              />
              <Typography variant="h5" className={classes.cardText}>
                {destination.name}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default PopularCruiseDestinations;
