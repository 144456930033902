import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

interface ProtectAdventureProps {
  image: string;
  title: string;
  description: string;
  actionText: string;
  route: string;
}

export default function ProtectAdventure({
  image,
  title,
  description,
  actionText,
  route,
}: ProtectAdventureProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenEmail = () => {
    const subject = `Inquiry - Protect Adventure`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  }

  return (
    <Container
      sx={{
        marginTop: 10,
        marginBottom: 10,
    
      }}
    >
        <Card
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            justifyContent: isSmallScreen ? "center" : "center",
            alignItems: "center", // Change to "stretch"
            minHeight: "200px",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems:  isSmallScreen ? "center" : "flex-start", // Center on small screens
              width: isSmallScreen ? "100%" : "50%",
              height: "100%",
              maxHeight: "100%",
              paddingLeft: isSmallScreen ? 0 : 0
            }}
          >
            <Typography
              variant="body1"
              component="div"
              textAlign={"left"}
              width={"100%"}
              sx={{
                marginBottom: "8%", // Space below the title
                color: "black",
                fontWeight: "bold",
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.8rem",
                  md: "1.9rem",
                  lg: "2.5rem",
                },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "8%", // Space below the description
                width: !isSmallScreen ? "90%" : "100%",
                color: "black",
                fontSize: {
                  xs: "1rem",
                  sm: "1.3rem",
                  md: "1.1rem",
                  lg: "1.2rem",
                },
              }}
            >
              {description}
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#DB504A",
                textTransform: "none",
                width: isSmallScreen ? "40%" : "32%",
                height: {
                  xs: 50,
                },
                fontSize: {
                  xs: "0.8rem",
                  lg: "1rem",
                },
                "&:hover": {
                  backgroundColor: "#165B76", // Change the background color on hover
                  color: "white",
                },
              }}
              onClick={handleOpenEmail}
            >
              {actionText}
            </Button>
          </CardContent>
          <CardMedia
            component="img"
            sx={{
              width: isSmallScreen ? "100%" : "50%",
              maxHeight: "600px", // Change to "auto"
            }}
            image={image}
            alt="Card Image"
          />
        </Card>
    </Container>
  );
}
