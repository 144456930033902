import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { EXPLORER_REWARDS } from "../Rewards/constants";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  createTheme,
  Container,
  Button,
  useMediaQuery,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

export type Place = {
  name: string;
  description: string;
  image: string;
  moreInfo: {
    description: string;
    images: string[];
  };
};
const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  cardMedia: {
    height: "400px",
  },
  cardContent: {
    flex: "1",
    backgroundColor: "white",
  },
  discountBadge: {
    textAlign: "center",
    backgroundColor: "#DB504A", // You can adjust the background color
    color: "white",
    borderRadius: 0,
    width: "100%",
    fontWeight: "bold",
    padding: 2,
  },
  learnMoreButton: {
    textAlign: "left",
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },
}));

export default function TourPlace() {
  const location = useLocation();
  const { place, tour } = useParams();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [venue, setVenue] = useState<Place | null>();

  useEffect(() => {
    if (place) {
      const PLACE = EXPLORER_REWARDS.find(
        (reward) => reward.name.toLowerCase() === place.toLowerCase()
      );

      const TOUR = PLACE?.places.find(
        (place) => place.name.toLocaleLowerCase() === tour?.toLocaleLowerCase()
      );
      setVenue(TOUR);
    }
  }, [place]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();

  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <Container>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          color={"#165B76"}
          marginY={4}
        >
          {tour}
        </Typography>

        {venue && (
          <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={venue.image}
              title={venue.name}
              sx={{ width: isSmallScreen ? "100%" : "50%" }}
            />
            <CardContent
              className={classes.cardContent}
              sx={{ width: isSmallScreen ? "100%" : "50%" }}
            >
              <Typography
                variant="h6"
                component="div"
                color={"#165B76"}
                fontWeight={"bold"}
                marginBottom={1}
              >
                {venue.name}
              </Typography>
              <Typography variant="body1" component="div" marginBottom={2}>
                {venue.description}
              </Typography>
            </CardContent>
          </Card>
        )}

        {venue && venue.moreInfo.description && (
          <Box marginTop={4}>
            <Typography variant="body1">
              {venue.moreInfo.description}
            </Typography>
          </Box>
        )}

        {venue && venue.moreInfo.images.length > 0 && (
          <Grid container spacing={2} marginTop={4}>
            {venue.moreInfo.images.map((image, index) => (
              <Grid item key={index} xs={12} md={4}>
                <Paper
                  style={{
                    paddingTop: "90%", // 1:1 aspect ratio (adjust as needed)
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}

        <Outlet />
      </Container>
    </div>
  );
}
