import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HotelIcon from "@mui/icons-material/Hotel";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ExplicitIcon from "@mui/icons-material/Explicit";
import Cookies from "js-cookie";
import Rating from "@mui/material/Rating";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  saveVacationDeal,
  unsaveVacationDeal,
  initDealsFromCookies,
} from "../../../../redux/store/index";

const useStyles = makeStyles((theme) => ({
  vacationDealCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    height: 450,
  },
  vacationDealCard: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 150, // Ensure that cards take up all available vertical space
  },
  card: {
    "&:hover": {
      transform: "scale(1.05)", // Scale the card on hover
      transition: "transform 1s ease-in-out", // Adjust the duration to make it slower
    },
    paddingBottom: 0,
  },
}));

export enum attractions {
  plane = "plane",
  bus = "bus",
  car = "car",
  allInclusive = "allInclusive",
  rooms = "rooms",
  camera = "camera",
  adult = "adult",
}
export type VacationDeal = {
  id: string;
  title: string;
  place: string;
  accommodation: string | undefined;
  adults: string | undefined;
  pricing: string;
  rating: number | undefined;
  image: string;
  morePhotos: string[];
  attractions: attractions[] | undefined;
  description: string | undefined;
};
interface VacationDealCardProps {
  deal: VacationDeal;
}

const iconMapping: { [key in attractions]: React.ReactNode } = {
  plane: <AirplanemodeActiveIcon />,
  bus: <DirectionsBusIcon />,
  car: <DirectionsCarIcon />,
  allInclusive: <AllInclusiveIcon />,
  rooms: <HotelIcon />,
  camera: <CameraAltIcon />,
  adult: <ExplicitIcon />,
};

export const VacationDealCard = ({ deal }: VacationDealCardProps) => {
  const [saved, setSaved] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const SAVED_DEALS: string[] = useSelector(
    (state: RootState) => state.savedDeals.vacationDeals
  );
  const isSaved = useSelector(
    (state: RootState) => state.savedDeals.vacationDeals.includes(deal.id)
  );

  const handlesaveVacationDeal = (dealId: string) => {
    dispatch(saveVacationDeal(dealId));
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const newSavedDeals = [...parsedDeals.vacationDeals, dealId];
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: newSavedDeals,
          cruiseDeals: parsedDeals.cruiseDeals,
        }),
        { expires: 365 }
      );
    } else {
      Cookies.set(
        "savedDeals",
        JSON.stringify({ vacationDeals: [dealId], cruiseDeals: [] }),
        { expires: 365 }
      );
    }
  };

  const handleUnsaveVacationDeal = (dealId: string) => {
    dispatch(unsaveVacationDeal(dealId));
    console.log()
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const filteredDeals = parsedDeals.vacationDeals.filter(
        (id) => id !== dealId
      );
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: filteredDeals,
          cruiseDeals: parsedDeals.cruiseDeals,
        }),
        { expires: 365 }
      );
    }
  };

  const handleSaveClick = () => {
    if (isSaved) {
      console.log("Unsaved");
      handleUnsaveVacationDeal(deal.id);
    } else {
      console.log("saved");
      handlesaveVacationDeal(deal.id);
    }
  };

  useEffect(() => {
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      if (parsedDeals.cruiseDeals && parsedDeals.vacationDeals) {
        dispatch(initDealsFromCookies(parsedDeals));
        setSaved(parsedDeals.vacationDeals.includes(deal.id));
      }
    }
  }, [deal.id, dispatch]);

  return (
    <>
      <Card className={classes.card}>
        <Link to={`/vacations/deal/?title=${deal.title}&id=${deal.id}`} style={{ textDecoration: "none" }}>
          <CardMedia
            component="img"
            alt={deal.title}
            height="200"
            image={deal.image}
          />
          <CardContent sx={{ paddingBottom: 0 }}>
            <div className={classes.vacationDealCard}>
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {deal.title}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "12px", color: "#165B76" }}
                >
                  {deal.place}
                </Typography>
                {/* Use the Rating component to display ratings */}
                <Rating
                  name="rating"
                  value={deal.rating} // Replace with the actual rating value from your data
                  precision={0.5} // Adjust as needed
                  readOnly
                  sx={{ color: "#DB504A" }}
                />
              </div>
              <Typography
                variant="body2"
                align="right"
                sx={{ marginBottom: 1, marginTop: 1, color: "#165B76" }}
              >
                {deal.pricing}
              </Typography>
              <Divider className="bright-divider" />
            </div>
          </CardContent>
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            {deal.attractions?.map((attraction, index) => (
              <span
                key={index}
                className="attraction-icon"
                style={{ color: "gray" }}
              >
                {iconMapping[attraction]}
              </span>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={(event) => {
                handleSaveClick();
              }}
            >
              {isSaved ? (
                <FavoriteIcon style={{ color: "#DB504A" }} />
              ) : (
                <FavoriteBorderIcon style={{ color: "#DB504A" }} />
              )}
            </IconButton>
            <Typography
              variant="body2"
              style={{ color: saved ? "#DB504A" : "" }}
            >
              {saved ? "Saved" : "Save"}
            </Typography>
          </div>
        </div>
      </Card>
    </>
  );
};

export default VacationDealCard;
