import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import StoreLocationCard from "./StoreLocationCard";
import { storeData } from "./constants";
import Breadcrumb from "../../components/Breadcrumbs";
import { Button, Typography } from "@mui/material";
import TravelExpertsModal from "../../components/TravelExpertsModal";
import { useLocation } from "react-router-dom";

const StoreLocations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Assuming storeData is an array of store details
  const stores = storeData.map((store, index) => (
    <Grid item xs={12} key={index}>
      <StoreLocationCard storeDetails={store} />
    </Grid>
  ));

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h4"
          color={"#165B76"}
          textAlign={"center"}
          marginBottom={2}
        >
          Store Locations
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          width={{
            xs: "90%",
            lg: "85%",
          }}
          marginBottom={5}
        >
          When you entrust Caribbean World Explorer with making your travel
          arrangements, it means developing a rapport and having a personalized
          experience with one of our experienced advisors. Our travel
          specialists are accessible both in-store and remotely for greater
          flexibility and convenience in order to uphold our commitment to
          providing knowledgeable advice and committed service. For the
          contemporary traveler, it's face-to-face client service.
        </Typography>
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Grid item xs={12} lg={12} marginBottom={2} alignItems={"center"}>
            <Typography textAlign={"center"} alignSelf={"center"} paddingX={5}>
              No store near you? No worries! We have consultants located across
              the country who work remotely.
            </Typography>
          </Grid>
          <Grid item xs={12} alignItems={"center"} marginBottom={5}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#DB504A",
                "&:hover": {
                  backgroundColor: "#DB504A",
                },
                "&:focus": { outline: "none" },
              }}
              onClick={handleOpenModal}
            >
              Browse Our Experts
            </Button>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={2} paddingX={{ lg: 8, xs: 2 }} marginBottom={5}>
        {stores}
      </Grid>
      <TravelExpertsModal open={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default StoreLocations;
