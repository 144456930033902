import React from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Box,
  createTheme,
  Divider,
  Tooltip,
  Rating,
} from "@mui/material";

import HotelIcon from "@mui/icons-material/Hotel";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { makeStyles } from "@mui/styles";

interface Favourite {
  image: string;
  country: string;
  hotelName: string;
  duration: string;
  adults: string;
  price: string;
  backgroundColor: string;
  rating: number;
}

const favouritesData: Favourite[] = [
  {
    image:
      "https://cf.bstatic.com/xdata/images/hotel/max1024x768/325314200.jpg?k=e7f7858b59ca717c8ad96d26720ce40653cddc9653a55ed1ffe5424e8d35d8f9&o=&hp=1",
    country: "JAMAICA",
    hotelName: "Iberostar Selection Rose Hall Suites",
    duration: "4 Nights & 5 Days",
    adults: "2 Adults",
    price: "From $1575",
    backgroundColor: "#165B76",
    rating: 5
  },
  {
    image:
      "https://www.palladiumhotelgroup.com/content/dam/palladium/content-fragments/hoteles/es/grand-palladium-colonial-resort-spa/galer%C3%ADa/im%C3%A1genes/habitaciones/junior-suite-garden-view/2022/GP-COLONIAL-JUNIOR%20SUITE-2015_MG_7638.jpg.transform/rendition-sm/image.jpg",
    country: "MEXICO",
    hotelName: "Grand Palladium Colonial Resort & Spa",
    duration: "4 Nights & 5 Days",
    adults: "2 Adults",
    price: "From $1073",
    backgroundColor: "#DB504A",
    rating: 5
  },
  {
    image:
      "https://marriott.cdn.tambourine.com/royalton-resorts/media/diamondclubtm-6241c61aeb71a.jpg",
    country: "DOMINICAN REPUBLIC",
    hotelName: "Royalton Splash Punta Cana Resort & Casino",
    duration: "4 Nights & 5 Days",
    adults: "2 Adults",
    price: "From $1105",
    backgroundColor: "#60941A",
    rating: 5
  },
];

const theme = createTheme();

const useStyles = makeStyles(() => ({
  card: {
    color: "white",
  },
  pricing: {
    textAlign: "right",
    fontSize: "1.5rem",
    color: "white",
  },
  iconRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  icon: {
    fontSize: "24px",
    marginRight: theme.spacing(1),
    color: "white",
  },
}));

const Favourites: React.FC = () => {
  const classes = useStyles();

  return (
    <Container>
      <Typography
        variant="h4"
        marginBottom={5}
        marginTop={10}
        textAlign={"center"}
        color={"#165B76"}
      >
        The Explorer's Bucketlist
      </Typography>
      <Typography variant="body1" marginBottom={5} textAlign={"center"}>
        Trying to figure out where to go next? Here are some recommendations.
      </Typography>
      <Grid container spacing={3}>
        {favouritesData.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              className={classes.card}
              style={{ backgroundColor: item.backgroundColor }}
            >
              <img
                src={item.image} // Replace with actual image URLs
                alt={item.country}
                style={{ height: "200px", width: "100%", objectFit: "cover" }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  color={"white"}
                  fontWeight={"bold"}
                >
                  {item.country}
                </Typography>
                <Divider
                  sx={{
                    marginTop: 3,
                    marginBottom: 3,
                    backgroundColor: "white",
                  }}
                />

                <Typography variant="subtitle1" color={"lightgray"}>
                  {item.hotelName}
                </Typography>
                <Typography variant="body2" color={"lightgray"}>
                  {item.duration} for {item.adults} | All Inclusive
                </Typography>
                <Rating value={item.rating} readOnly size="small" style={{padding: 0}}/>
                <Typography variant="body2" className={classes.pricing}>
                  {item.price}
                </Typography>
                <Divider sx={{ marginTop: 3, backgroundColor: "white" }} />
                <Box className={classes.iconRow}>
                  <Tooltip title="Hotel">
                    <HotelIcon className={classes.icon} />
                  </Tooltip>
                  <Tooltip title="All-Inclusive">
                    <AllInclusiveIcon className={classes.icon} />
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Favourites;
