import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { createTheme } from "@mui/material";
import TravelExpertsModal from "../TravelExpertsModal";

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});
const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2), // Adjust padding for small screens
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 10), // Adjust padding for medium screens
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5, 30), // Adjust padding for large screens
    },
    marginBottom: theme.spacing(5),
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  body: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2), // Adjust padding for small screens
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4, 10), // Adjust padding for medium screens
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5, 15), // Adjust padding for large screens
    },
  },
  ctaColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ctaIcon: {
    fontSize: 48,
    color: "#DB504A",
  },
  actionText: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  ctaLink: {
    textDecoration: "none",
    color: "#DB504A",
    fontWeight: "bolder",
    cursor: "pointer",
  },
}));

function Cta() {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        The CWE Experience
      </Typography>
      <Typography variant="inherit" className={classes.body}>
        Here at Caribbean World Explorer, you matter. We know what it takes to
        plan and book a trip and we’ll take care of everything from booking your
        flight, cruise, tour and hotel to planning your itinerary and arranging
        transportation. We will hold your hands through the process and be with
        you every step of the way.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={4} className={classes.ctaColumn}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PhoneIcon className={classes.ctaIcon} fontSize="large" />
            <Typography variant="body2" className={classes.actionText}>
              Connect By Phone
            </Typography>
            <a href="tel:1234567890" className={classes.ctaLink}>
              +1 (854) 233-0993
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.ctaColumn}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <EmailIcon className={classes.ctaIcon} fontSize="large" />
            <Typography variant="body2" className={classes.actionText}>
              Connect By Email
            </Typography>
            <a href="mailto:info@example.com" className={classes.ctaLink}>
              info@caribbeanworldexplorer.com
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.ctaColumn}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PersonSearchIcon className={classes.ctaIcon} fontSize="large" />
            <Typography variant="body2" className={classes.actionText}>
              Meet Our Experts
            </Typography>
            <Typography
              className={classes.ctaLink}
              fontWeight={"bolder"}
              onClick={toggleModal}
            >
              Connect with an expert
            </Typography>
          </div>
        </Grid>
      </Grid>
      <TravelExpertsModal open={showModal} onClose={toggleModal} />
    </div>
  );
}

export default Cta;
