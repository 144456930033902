import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  createTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const cardData = [
  {
    imageSrc:
      "https://www.libertytravel.com/sites/default/files/styles/full_size/public/Groups%20Product%20Tiles-Weddings-1262x500.jpg?itok=5b2a3cKy",
    title: "Weddings",
    description:
      "True love is an incredible experience. Allow us to discover the perfect destination and venue to commemorate your special day, ensuring you create unforgettable memories that will last a lifetime.",
  },
  {
    imageSrc:
      "https://www.libertytravel.com/sites/default/files/styles/full_size/public/Groups%20Product%20Tiles-Celebrations-1262x500.jpg?itok=t8BTk2fJ",
    title: "Celebrations",
    description:
      "Celebrating and exploring new adventures with your loved ones should always be a priority. Let us handle the planning for your next exciting journey.",
  },
  {
    imageSrc:
      "https://www.libertytravel.com/sites/default/files/styles/full_size/public/Groups%20Product%20Tiles-Meetings-1262x500.jpg?itok=2qV8QTk_",
    title: "Meetings",
    description:
      "Motivation and recognition can boost your team’s productivity and results. Encourage a strong work-play balance by treating them to a weekend getaway in a fresh environment. We’re here to assist you in inspiring your team with a change of scenery.",
  },
  {
    imageSrc:
      "https://www.libertytravel.com/sites/default/files/styles/full_size/public/Groups%20Product%20Tiles-Organizations-1262x500.jpg?itok=NhQDjvBV",
    title: "Organizations",
    description:
      "Your purpose is significant, and travel has the ability to bring people closer. Regardless of whether you’re organizing a school excursion, volunteer mission, or a spiritual group retreat, CWE can arrange your flights, accommodations, and tours for a seamless experience.",
  },
];

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  card: {
    position: "relative",
    maxWidth: "100%",
    borderRadius: 0,
  },
  cardImage: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      height: "auto", // Set width to 40% on large screens
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto", // Set width to 40% on large screens
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4))", // Adjust the gradient as needed
  },
  textContainer: {
    position: "absolute",
    top: theme.spacing(0), // Adjust the padding as needed
    left: theme.spacing(0), // Adjust the padding as needed
    color: "#fff", // Adjust the text color,
    [theme.breakpoints.up("md")]: {
      width: "40%", // Set width to 40% on large screens
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto", // Set width to 55% on small screens
    },
  },
  title: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem", // Set width to 40% on large screens
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "auto", // Set width to 55% on small screens
    },
    fontWeight: "bold",
    marginBottom: theme.spacing(5),
  },
  description: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "auto", // Set width to 40% on large screens
    },
  },
}));

const FullWidthCard = ({
  imageSrc,
  title,
  description,
}: {
  imageSrc: string;
  title: string;
  description: string;
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className={classes.card}>
      <img src={imageSrc} alt={title} className={classes.cardImage} />
      <div className={classes.overlay}></div>
      <CardContent className={classes.textContainer}>
        <Typography variant="h5" className={classes.title} marginBottom={3}>
          {title}
        </Typography>
        {!isSmall && (
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export function GroupTypes() {
  return (
    <Container sx={{ paddingBottom: theme.spacing(10) }}>
      <Grid container spacing={6}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
            <FullWidthCard
              imageSrc={card.imageSrc}
              title={card.title}
              description={card.description}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
