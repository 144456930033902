import React, { ReactNode, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, unsaveCruiseDeal, unsaveVacationDeal } from "../../redux/store/index"; // Adjust the import path to match your file structure
import {
  VacationDealCard,
  VacationDeal,
} from "../VacationsPage/VacationDeals/VacationCard"; // Import the VacationDealCard component
import { vacationDeals } from "../VacationsPage/constants";
import { cruiseDeals } from "../CruisesPage/constants";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Container,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import Breadcrumb from "../../components/Breadcrumbs";
import Cookies from "js-cookie";
import CruiseDealCard from "../CruisesPage/VacationDeals/VacationCard";
import { useLocation } from "react-router-dom";

const SavedDealsPage = () => {
  // Use useSelector to access the saved deals from the Redux store
  const deals = useSelector((state: RootState) => state.savedDeals);
  const savedDeals = [...deals.cruiseDeals, ...deals.vacationDeals];
  const allDeals = [...vacationDeals, ...cruiseDeals];

  const dispatch = useDispatch();

  const [sortedDealItems, setSortedDealItems] = useState<VacationDeal[]>(
    allDeals.filter((deal) => savedDeals.includes(deal.id))
  );

  const [sortBy, setSortBy] = useState(""); // State to store sorting option

  const clearSavedDeals = () => {
    // Clear saved deals in Redux state
    savedDeals.forEach((dealId) => {
      if (dealId.startsWith("V")) {
        dispatch(unsaveVacationDeal(dealId))
      }  else {
        dispatch(unsaveCruiseDeal(dealId))
      }
    })
    // Clear Cookies
    Cookies.remove("savedDeals");
    setSortedDealItems([])
  };

  const sortDeals = (sortingOption: string) => {
    let sortedDeals: VacationDeal[] = [...sortedDealItems];

    switch (sortingOption) {
      case "title-asc":
        sortedDeals.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case "title-desc":
        sortedDeals.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case "price-asc":
        sortedDeals.sort(
          (a, b) =>
            parseFloat(a.pricing.split("$")[1]) -
            parseFloat(b.pricing.split("$")[1])
        );
        break;
      case "price-desc":
        sortedDeals.sort(
          (a, b) =>
            parseFloat(b.pricing.split("$")[1]) -
            parseFloat(a.pricing.split("$")[1])
        );
        break;
        case "rating-asc":
          sortedDeals.sort((a, b) => {
            if (a.rating === undefined && b.rating === undefined) return 0;
            if (a.rating === undefined) return -1;
            if (b.rating === undefined) return 1;
            return a.rating - b.rating;
          });
          break;
        case "rating-desc":
          sortedDeals.sort((a, b) => {
            if (a.rating === undefined && b.rating === undefined) return 0;
            if (a.rating === undefined) return 1;
            if (b.rating === undefined) return -1;
            return b.rating - a.rating;
          });
          break;
      default:
        sortedDeals = allDeals.filter((deal) => savedDeals.includes(deal.id))
        break;
    }
    setSortedDealItems(sortedDeals);
  };

  const handleSortChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    const selectedSort = event.target.value;
    setSortBy(selectedSort);
  };

  useEffect(() => {
    sortDeals(sortBy); // Sort deals initially based on the selected sorting option
  }, [sortBy, deals]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginY={sortedDealItems.length === 0 ? 15 : 20}
      height={"100%"} // Center content vertically and horizontally
    >
      <Breadcrumb showSearch={false} />
      {sortedDealItems.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FavoriteIcon
            color="error"
            fontSize="large"
            sx={{ fontSize: 100, marginBottom: 3 }}
          />
          <Typography
            variant="h4"
            component="strong"
            color="error"
            marginBottom={3}
            textAlign={"center"}
          >
            Your saved deals will appear here
          </Typography>
          <Typography variant="body1" textAlign={"center"} paddingX={{xs:5, sm:5, md: 10, lg: 10}}>
            When you see something you like, use the heart to save it, and it
            will appear here for you to see again.
          </Typography>
        </div>
      ) : (
        <Container sx={{ marginBottom: 10 }}>
          <Grid
            container
            spacing={2}
            marginBottom={10}
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ color: "#165B76", textAlign: "left" }}
              >
                Saved Deals
              </Typography>
              <Typography sx={{ color: "gray", textAlign: "left" }}>
                You have {sortedDealItems.length} saved deals
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} display={"flex"} flexDirection={"row"}>
              <FormControl sx={{ marginRight: 2, width: "60%" }} variant="standard">
                <InputLabel>Sort By</InputLabel>
                <Select value={sortBy} onChange={handleSortChange}>
                  <MenuItem value="default">Default</MenuItem>
                  <MenuItem value="title-asc">Title A-Z</MenuItem>
                  <MenuItem value="title-desc">Title Z-A</MenuItem>
                  <MenuItem value="price-asc">Price Low-High</MenuItem>
                  <MenuItem value="price-desc">Price High-Low</MenuItem>
                  <MenuItem value="rating-asc">Rating 1-5</MenuItem>
                  <MenuItem value="rating-desc">Rating 5-1</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                onClick={clearSavedDeals}
                sx={{
                    color: "white",
                    backgroundColor: "#165B76",
                    textTransform: "none",
                    width: "fit-content",
                    height: 40,
                    fontSize: "0.9rem",
                    "&:hover": {
                      backgroundColor: "#165B76",
                      color: "white",
                      border: "none"
                    },
                    border: "none"
                  }}
              >
                Clear Deals
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {sortedDealItems.map((deal, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                {deal.id.startsWith("V") && <VacationDealCard deal={deal} />}
                {deal.id.startsWith("C") && <CruiseDealCard deal={deal} />}

              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default SavedDealsPage;
