export const destinations = [
    {
      name: "Jamaica",
      image: "https://assets.hyatt.com/content/dam/hyatt/hyattdam/images/2015/10/07/1116/Hyatt-Zilara-Rose-Hall-P497-Pool-Swim-Up.jpg/Hyatt-Zilara-Rose-Hall-P497-Pool-Swim-Up.16x9.jpg",
    },
    {
        name: "Mexico",
        image: "https://www.thecuriouscreature.com/wp-content/uploads/2018/10/Marquis-Los-Cabos.jpg"
    },
    {
        name: "Dubai",
        image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/14/12/b8/view-from-private-pool.jpg?w=1200&h=-1&s=1"
    },
    {
      name: "St Lucia",
      image: "https://www.islandlifecaribbean.com/wp-content/uploads/2021/12/st-lucia-all-inclusive-resorts-1.jpg",
    },
    {
      name: "Aruba",
      image: "https://www.riu.com/en/binaris/hotel-riu-palace-aruba_tcm55-228485.jpg?v=tm270120_1300",
    },
    {
      name: "Cancun",
      image: "https://imageio.forbes.com/specials-images/imageserve/64ca798455fd04f0ccc6f9a3/Cancun-beach-coast-with-sunsets/0x0.jpg?format=jpg&height=1143&width=1809",
    },
    {
      name: "Los Cabos",
      image: "https://www.onecause.com/wp-content/uploads/2021/02/Los-Cabos1.jpg",
    },
    {
      name: "Riviera Maya",
      image: "https://misstourist.com/wp-content/uploads/2022/01/0-All-Inclusive-Resorts-in-Riviera-Maya.jpg",
    },
    {
        name: "Florida",
        image: "https://www.touropia.com/gfx/d/beach-resorts-in-florida/The_Ritz_Carlton_Naples.jpg"
    },
    {
        name: "Europe",
        image: "https://www.planetware.com/wpimages/2020/08/europe-best-all-inclusive-resorts-westin-dragonara-resort-malta.jpg"
    },
    {
        name: "Bali",
        image: "https://balidave.com/wp-content/uploads/2022/11/best-hotel-bali.jpeg"
    },
    {
        name: "Tulum",
        image: "https://insiderstulum.com/wp-content/uploads/2023/01/Azulik-Hotel-1024x683.jpg"
    },
    {
        name: "Greece",
        image: "https://portozante.com/wp-content/uploads/2019/04/Porto-Zante-Luxury-Villas_Greece-Zakynthos-Greek-Islands-Heated-Pool-Beachfront-Villas-Luxury_Family_Resorts-1-2018_JDCD9A0997a-top_1400px.jpg"
    },
    {
        name: "Hawaii",
        image: "https://www.smartertravel.com/wp-content/uploads/2019/01/Romantic-Hawaii-Resorts-Princeville-Kauai-hero.jpg"
    },
    {
        name: "Maldives",
        image: "https://www.journeyera.com/wp-content/uploads/2022/01/BEST-MALDIVES-HONEYMOON-RESORTS.jpg"
    },
    {
        name: "Rome",
        image: "https://romecavalieri.com/wp-content/uploads/2016/03/Party-in-Planetarium-Suite-Rooftop-Terrace-e1469603819575.jpg"
    },
    {
        name: "Bora Bora",
        image: "https://www.kayak.com/rimg/himg/df/bc/85/leonardo-230237680-DINER_ROMANTIQUE-4_O-976107.jpg?width=1366&height=768&crop=true"
    },
    {
        name: "Myrtle Beach",
        image: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/241668241.jpg?k=46252a9f8055fe8eb5018a5c56804da00f7423ac12380c33efcf91542662cf89&o=&hp=1"
    },
    {
        name: "Turks & Caicos",
        image: "https://images.trvl-media.com/lodging/7000000/6330000/6326300/6326300/5d8886a2_y.jpg"
    },
    {
        name: "Barbados",
        image: "https://cdn.sandals.com/sandals/v13/images/EN/uploads/sbr_overview_002_aa4004d4a2.jpg"
    },
    {
        name: "Bahamas",
        image: "https://familyvacationist.com/wp-content/uploads/2021/04/hero-bahamas-all-inclusive-1600x1067.jpg"
    },
    {
        name: "British Virgin Islands",
        image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/29/29/a8/f8/scrub-island-aerial-at.jpg?w=1200&h=-1&s=1"
    },
   

  ];