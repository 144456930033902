import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

interface FindExpertProps {
  image: string;
  title: string;
  description: string;
  actionText: string;
  route: string;
}

function FindExpert({
  image,
  title,
  description,
  actionText,
  route,
}: FindExpertProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleConnect = () => {
    const subject = `Information Request`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  }

  return (
    <Box
      sx={{
        backgroundColor: "white",
        margin: 0,
        padding: isSmallScreen ? 1 : 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto", // Set to 100vh for full viewport height
      }}
    >
      <Container maxWidth="lg">
        <Card
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "stretch", // Change to "stretch"
            minHeight: "200px",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: isSmallScreen ? "100%" : "40%",
              height: "auto", // Change to "auto"
              marginBottom: isSmallScreen ? 2 : 0,
            }}
            image={require("../../../assets/sharmeel.png")}
            alt="Card Image"
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // Center content vertically
              alignItems: isSmallScreen ? "center" : "flex-start", // Center on small screens
              width: isSmallScreen ? "100%" : "60%",
              height: "100%",
              maxHeight: "100%",
              alignSelf: "center"
            }}
          >
            <Typography
              variant="body1"
              component="div"
              textAlign={"left"}
              sx={{
                marginBottom: "8%", // Space below the title
                color: "black",
                fontWeight: "bold",
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.8rem",
                  md: "1.9rem",
                  lg: "2.5rem",
                },
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "8%", // Space below the description
                width: !isSmallScreen ? "90%" : "100%",
                color: "black",
                fontSize: {
                  xs: "1rem",
                  sm: "1.3rem",
                  md: "1.1rem",
                  lg: "1.2rem",
                },
              }}
            >
              {description}
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "#DB504A",
                textTransform: "none",
                width: "fit-content",
                height: {
                  xs: 50
                },
                fontSize: {
                  xs: "0.8rem",
                  lg: "1rem"
                },
                "&:hover": {
                  backgroundColor: "#165B76", // Change the background color on hover
                  color: "white"
                },
              }}
              onClick={handleConnect}
            >
              {actionText}
            </Button>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

export default FindExpert;
