import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

const resortTypes = [
  {
    title: "All Inclusive",
    description: "This typically includes accommodation, meals, drinks and activities."
  },
  {
    title: "Family",
    description: "Explore a wide range of amenities and activities suitable for all ages, such as kids' clubs, pools with waterslides, family-friendly entertainment, and dining options.",
  },
  {
    title: "Adults Only",
    description:
      "These resorts are ideal for those 18 years and older who are seeking a peaceful and romantic atmosphere without the presence of children.",
  },
  {
    title: "Honeymoon",
    description:
      "We’ll custom make your honeymoon vacation package.",
  },
  {
    title: "Casino",
    description:
      "We’ll help you find the best Casino destination.",
  },
  {
    title: "Exotic",
    description:
      "These resorts are often located in remote or stunning natural locations, offering a combination of luxury and adventure. If you have a specific type of exotic destination in mind or need recommendation, please let us know.",
  },
  {
    title: "Last Minute",
    description: "We’ll book your flight or accommodation within a few days or even hours of departure. Connect with us today!"
  },
  {
    title: "Golf and Spa",
    description:
      "Explore destinations that cater to both golf enthusiasts and those seeking relaxation and wellness.",
  },
  {
    title: "Luxury",
    description:
      "Explore high-end vacation destinations that provide exceptional accommodations, amenities, and services to pamper and cater to the needs of their guests.",
  },
  {
    title: "Cruise",
    description: "Sail the world and explore multiple destinations in one trip.",
  },
];

export default function ResortTypesGlance() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        margin: 0,
        marginTop: 10,
        marginBottom: 10,
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "auto", // Set to 100vh for full viewport height
        width: "100%",
      }}
    >
      {/* <Container maxWidth={false} sx={{margin: 0, padding: 0}}> */}
      <Card
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "stretch",
          minHeight: "100vh", // Set card height to 100% of the viewport
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: 0,
          width: "100%",
          borderRadius: 0
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: isSmallScreen ? "100%" : "50%", // Image takes 50% of the card width
            height: "auto",
            borderRadius: 0,
          }}
          image={
            "https://images.pexels.com/photos/450038/pexels-photo-450038.jpeg?auto=compress&cs=tinysrgb&w=600"
          }
          alt="Card Image"
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center", // Center content vertically
            width: isSmallScreen ? "100%" : "50%", // Content takes 50% of the card width
            backgroundColor: "#165B76", // Set the background color for the content
            color: "white", // Set text color to white
            padding: isSmallScreen ? 2 : 10,
          }}
        >
          <Typography
            variant="body1"
            component="div"
            sx={{
              marginBottom: "8%", // Space below the title
              fontWeight: "bold",
              fontSize: {
                xs: "1.5rem",
                sm: "1.8rem",
                md: "1.9rem",
                lg: "2.1rem",
              },
            }}
          >
            Explore Our Vacation Types
          </Typography>
          {resortTypes.map((resort, index) => (
            <div key={index}>
              <Typography
                variant="h6"
                fontSize={"1rem"}
                color={"#DB504A"}
                fontWeight={"bold"}
                sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
              >
                {resort.title}
                <Typography
                  variant="body1"
                  fontSize={"1rem"}
                  color={"white"}
                >
                  {resort.description}
                </Typography>
              </Typography>
            </div>
          ))}
        </CardContent>
      </Card>
      {/* </Container> */}
    </Box>
  );
}
