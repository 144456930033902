import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { EXPLORER_REWARDS } from "./constants";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  createTheme,
  Container,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

export type Place = {
  name: string;
  description: string;
  image: string;
  moreInfo: {
    description: string;
    images: string[]
  }
};

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  card: {
    width: "100%",
    // margin: theme.spacing(2),
  },
  cardMedia: {
    height: 270,
  },
  discountBadge: {
    textAlign: "center",
    backgroundColor: "#DB504A", // You can adjust the background color
    color: "white",
    borderRadius: 0,
    width: "100%",
    fontWeight: "bold",
    padding: 2,
  },
  learnMoreButton: {
    textAlign: "left",
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },
}));

export default function Rewards() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { place: to } = useParams();

  const [rewards, setRewards] = useState<Place[]>();

  useEffect(() => {
    if (to) {
      const foundReward = EXPLORER_REWARDS.find(
        (reward) => reward.name.toLowerCase() === to.toLowerCase()
      );
      setRewards(foundReward?.places);
    }
  }, [to]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();

  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />

      <Container>
        <Typography
          variant="h4"
          fontWeight={"bold"}
          color={"#165B76"}
          marginY={4}
        >
          Explore Tours In {to}
        </Typography>
        <Grid container spacing={2}>
          {rewards &&
            rewards.map((place, index) => (
              <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={place.image}
                    title={place.name}
                  ></CardMedia>
                  <CardContent sx={{ backgroundColor: "white" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      color={"#165B76"}
                      fontWeight={"bold"}
                      marginBottom={1}
                    >
                      {place.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      marginBottom={2}
                    >
                      {place.description.length > 200
                        ? `${place.description.slice(0, 200)}...`
                        : place.description}
                    </Typography>
                    <Box className={classes.learnMoreButton}>
                      <Button
                        component={Link}
                        to={`/tours/${to}/${place.name}`}
                        variant="contained"
                        sx={{
                          backgroundColor: "#DB504A",
                          width: "fit-content",
                          "&:hover": {
                            backgroundColor: "#DB504A",
                            color: "white"
                          }
                        }}
                      >
                        Learn More
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
        <Outlet />
      </Container>
    </div>
  );
}
