import React from "react";
import Grid from "@mui/material/Grid";
import VacationDealCard from "./VacationCard";
import { vacationDeals } from "../constants";
import { Container, Typography } from "@mui/material";

const VacationDealsGrid = () => {
  return (
    <Container sx={{ marginBottom: 10, marginLeft: 0 }}>
      {" "}
      <Typography
        variant="h4"
        sx={{ color: "#165B76", textAlign: "center", marginBottom: 4 }}
      >
        Vacation Packages
      </Typography>
      <Grid container spacing={2}>
        {vacationDeals.map((deal, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <VacationDealCard deal={deal} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default VacationDealsGrid;
