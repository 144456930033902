import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@mui/material";
import { LATESTUPDATES } from "./constants";

interface CustomCardProps {
  image: string;
  description: string;
}

function CustomCard({ image, description }: CustomCardProps) {
  const handleInquire = () => {
    const subject = `Inquiry - ${description}`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <Card style={{ position: "relative", padding: 0 }}>
      <img src={image} alt="Card" style={{ width: "100%", height: 170 }} />
      <CardContent
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          textAlign: "center",
          width: "65%",
          padding: 0,
          marginLeft: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "white",
            width: "100%",
            fontWeight: "bold",
            fontSize: {
              lg: "1rem",
              md: 30,
              sm: 20,
              xs: 20,
            },
            textAlign: "left",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          backgroundColor: "transparent",
          justifyContent: "flex-end",
          width: "30%",
          padding: 0,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "white",
            width: "100%",
            textTransform: "none",
            borderColor: "white",
            fontSize: {
              xs: "0.7rem",
              sm: "0.8rem",
              md: "1rem",
            },
            marginRight: 1,
            marginBottom: 1,
            padding: 0.3,
            "&:hover": { borderColor: "white" },
          }}
          onClick={handleInquire}
        >
          Explore
        </Button>
      </CardActions>
    </Card>
  );
}

function LatestUpdates() {
  return (
    <Container sx={{ marginBottom: 10, marginTop: 10 }}>
      <Typography
        variant="h4"
        sx={{ color: "#165B76", textAlign: "center", marginBottom: 4 }}
      >
        Explore Our Resorts
      </Typography>
      <Grid container spacing={2}>
        {LATESTUPDATES.map((card) => (
          <Grid key={card.id} item xs={12} sm={6} md={6} lg={3}>
            {/* Use different grid sizes based on screen width */}
            <CustomCard image={card.image} description={card.description} />
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="body1"
        sx={{ marginTop: 4, fontWeight: "bold", textAlign: "center", width: "100%" }}
      >
        Contact Us to Find Out More About Our Other Hotels &amp; Resorts
        Worldwide.
      </Typography>
    </Container>
  );
}

export default LatestUpdates;
