import React from "react";
import { Grid, Paper, Typography, createTheme, ThemeProvider } from "@mui/material";
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import StarRateIcon from '@mui/icons-material/StarRate';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const theme = createTheme();

const useStyles = {
  valuesContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  valueItem: {
    padding: theme.spacing(0),
    textAlign: "center",
  },
  valueIcon: {
    fontSize: "4rem", // Adjust the icon size as needed,
    color: "#DB504A"
  },
};

const ValuesSection = () => {
  return (
    <ThemeProvider theme={theme}>
      <div style={useStyles.valuesContainer}>
        <Grid container spacing={2}>
          {/* Value 1 */}
          <Grid item xs={6} sm={6} md={3}>
            <Paper elevation={3} sx={{...useStyles.valueItem, boxShadow: "none"}}>
              <div style={useStyles.valueIcon}>
                <LocalPoliceIcon fontSize="inherit" color="inherit"/>
              </div>
              <Typography variant="h6"  color={"#165B76"} sx={{fontWeight: "bold"}}>Trust</Typography>
              <Typography variant="body1" color={"black"}>Dependable, Always</Typography>
            </Paper>
          </Grid>

          {/* Value 2 */}
          <Grid item xs={6} sm={6} md={3}>
            <Paper elevation={3} sx={{...useStyles.valueItem, boxShadow: "none"}}>
              <div style={useStyles.valueIcon}>
                <StarRateIcon fontSize="inherit" color="inherit"/>
              </div>
              <Typography variant="h6" color={"#165B76"} sx={{fontWeight: "bold"}}>Value</Typography>
              <Typography variant="body1" color={"#black"}>Value Beyond Compare</Typography>
            </Paper>
          </Grid>

          {/* Value 2 */}
          <Grid item xs={6} sm={6} md={3}>
            <Paper elevation={3} sx={{...useStyles.valueItem, boxShadow: "none"}}>
              <div style={useStyles.valueIcon}>
               <ContactSupportIcon fontSize="inherit" color="inherit"/>
              </div>
              <Typography variant="h6" color={"#165B76"} sx={{fontWeight: "bold"}}>Advice</Typography>
              <Typography variant="body1" color={"#black"}>Expert Advice, Tailored Vacations</Typography>
            </Paper>
          </Grid>

          {/* Value 2 */}
          <Grid item xs={6} sm={6} md={3}>
            <Paper elevation={3} sx={{...useStyles.valueItem, boxShadow: "none"}}>
              <div style={useStyles.valueIcon}>
                <VolunteerActivismIcon fontSize="inherit" color="inherit"/>
              </div>
              <Typography variant="h6" color={"#165B76"} sx={{fontWeight: "bold"}}>Service</Typography>
              <Typography variant="body1" color={"#black"}>Customer-Centric Excellence</Typography>
            </Paper>
          </Grid>

          {/* Add two more value items with the same structure */}
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default ValuesSection;
