import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import Breadcrumb from "../../components/Breadcrumbs";
import { useLocation } from "react-router-dom";

const TermsAndConditionsPage = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ marginTop: 96, marginBottom: 50 }}>
      <Breadcrumb showSearch={false} />
      <Container>
        <Typography variant="h4" gutterBottom marginTop={5} color={"#165B76"}>
          Terms and Conditions
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1. Baggage Fees:"
              secondary="Additional charges for luggage may be applicable. Travelers are encouraged to contact the airline or visit their website for detailed baggage policy information."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="2. Special Promotions:"
              secondary="Typically, special offers are only valid for new bookings."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="3. Pricing:"
              secondary="Prices are generally quoted per person and based on double occupancy accommodations. Airfare prices are for roundtrip travel from specific cities. Tour and land packages may not include meals, unless otherwise stated."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="4. Savings:"
              secondary="Savings typically reflect land costs only and may vary depending on the resort and travel dates."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="5. Availability:"
              secondary="The availability of seats may be limited on flights or tours."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="6. Holiday Blackouts:"
              secondary="Rates and package prices for airfares, cruises, and tours can be subject to blackout periods during holidays, and there may be surcharges during peak periods. Cancellation fees could apply, depending on the fare or package and when it's canceled."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="7. Government Taxes and Fees:"
              secondary="Depending on the chosen itinerary, U.S. or international government-imposed taxes and fees of up to $60 may be imposed and should be paid to the relevant airport authority."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="8. Refundability:"
              secondary="While some fares may not be refundable, they may often be exchanged for a fee, in addition to any extra airfare costs resulting from differences in new airfares."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="9. Other Restrictions:"
              secondary="There may be additional restrictions, and these can differ based on the resort, cruise, and airline carrier."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="10. Contact Information:"
              secondary="It is advised that travelers reach out to Caribbean World Explorer Inc. for further details regarding their bookings."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="11. Disclaimer:"
              secondary="The disclaimer clarifies that Caribbean World Explorer Inc. does not accept responsibility for any errors or omissions in the content of the offers displayed."
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary="12. Booking Options:"
              secondary="The text mentions that the selected offers can be accessed through consultants at Caribbean World Explorer Inc. or through third-party independent advisors affiliated with the Independent by Caribbean World Explorer Inc. network. Some offers and products may only be available via retail consultants at Caribbean World Explorer Inc."
            />
          </ListItem>
        </List>
      </Container>
    </div>
  );
};

export default TermsAndConditionsPage;
