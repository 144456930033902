import { VacationDeal } from "../VacationsPage/VacationDeals/VacationCard";

export const cruiseDeals: VacationDeal[] = [
  {
    id: "C1",
    title: "Royal Caribbean International",
    description: "Experience the epitome of luxury and adventure with Royal Caribbean International. Sail to the most breathtaking destinations on earth, enjoy world-class amenities, and create unforgettable memories onboard their magnificent ships.",
    place: "",
    pricing: "",
    image: "https://www.cruisemapper.com/images/ships/2180-225ef6390e5.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C2",
    title: "Carnival Cruise Line",
    description: "Carnival Cruise Line invites you to a world of pure fun and excitement. Embark on a journey filled with vibrant entertainment, delectable cuisine, and the spirit of celebration. It's the perfect escape for those who seek joy and relaxation.",
    place: "",
    pricing: "",
    image: "https://s3.amazonaws.com/a-us.storyblok.com/f/1005231/ba41ceb059/carnival-cruise-line_carnival-imagination_81.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C3",
    title: "Princess Cruises",
    description: "Princess Cruises offers elegance at sea. With a touch of sophistication, explore enchanting destinations in style. Delight in impeccable service, gourmet dining, and captivating entertainment while sailing with Princess.",
    place: "",
    pricing: "",
    image: "https://www.cruisemapper.com/images/lines/3-142937e1bf7198.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C4",
    title: "Norwegian Cruise Line",
    description: "Norwegian Cruise Line brings you the freedom to cruise your way. Discover a world of choices, from fine dining to thrilling activities. Set sail with Norwegian for an unforgettable vacation where every moment is yours to enjoy.",
    place: "",
    pricing: "",
    image: "https://imageio.forbes.com/specials-images/imageserve/61e538e102cb41ce9eb0f30d/0x0.jpg?format=jpg&width=1200",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C5",
    title: "MSC Cruises",
    description: "MSC Cruises provides a taste of Mediterranean elegance. Immerse yourself in the beauty of the seas, savor gourmet Italian cuisine, and indulge in impeccable service. It's a journey that blends relaxation and culture seamlessly.",
    place: "",
    pricing: "",
    image: "https://skift.com/wp-content/uploads/2017/12/mscseaside-1024x683.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C6",
    title: "TUI Cruises",
    description: "TUI Cruises offers a voyage of tranquility and exploration. Embark on a journey that balances relaxation and adventure, all while surrounded by the comfort of modern luxury. It's the perfect escape for discerning travelers.",
    place: "",
    pricing: "",
    image: "https://www.cruisehive.com/wp-content/uploads/2022/11/meinschiff7-1.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C7",
    title: "Holland America Line",
    description: "Holland America Line epitomizes classic cruising. Sail in style with timeless elegance, exquisite dining, and exceptional service. Experience a world of refinement and culture as you explore the globe.",
    place: "",
    pricing: "",
    image: "https://www.cruisemapper.com/images/ships/1337-f880997061f5f1172e477cbbf11a53aa.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C8",
    title: "Celebrity Cruises",
    description: "Celebrity Cruises is where modern luxury meets exploration. Discover a world of sophistication, fine dining, and unparalleled service. Sail with Celebrity and experience the height of elegance.",
    place: "",
    pricing: "",
    image: "https://www.celebritycruises.com/content/dam/celebrity/new-images/ship-things-to-do-onboard/ships/CEL-Eclipse-Aerial-2560x1440.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C9",
    title: "Viking Cruise",
    description: "Viking Cruise offers a journey into the heart of exploration. Experience the rich history and culture of destinations as you sail in Scandinavian-inspired luxury. It's a voyage for those who seek both knowledge and relaxation.",
    place: "",
    pricing: "",
    image: "https://thepointsguy.global.ssl.fastly.net/us/originals/2022/03/Viking-Cruises_Cruise-Stock_Viking-Sky-Eidfjord-Clouds.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C10",
    title: "Virgin Voyages",
    description: "Virgin Voyages redefines cruising with a dash of rebellious luxury. Embark on a journey where your desires take center stage, from exceptional dining to immersive experiences. It's a voyage like no other.",
    place: "",
    pricing: "",
    image: "https://eatsleepcruise.com/wp-content/uploads/2022/08/Whats-Changed-Since-Our-Last-Cruise-on-Virgin-Voyages-Scarlet-Lady-feature-1024x597.jpg.optimal.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C11",
    title: "Seabourn Cruise Line",
    description: "Seabourn Cruise Line is the pinnacle of intimate luxury cruising. Sail in understated elegance, savor gourmet dining, and explore exclusive destinations. It's a voyage for those who appreciate the finer things in life.",
    place: "",
    pricing: "",
    image: "https://s3.amazonaws.com/a-us.storyblok.com/f/1005231/63693c05ec/seabourn-quest_bow_orientation-right_sea_seabourn-cruise-line_2700.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C12",
    title: "Avalon Waterways",
    description: "Avalon Waterways offers a tranquil journey through Europe's waterways. Experience the charm of river cruising, with spacious staterooms and immersive excursions that unveil the beauty of historic towns and landscapes.",
    place: "",
    pricing: "",
    image: "https://www.cruiseandferry.net/Portals/0/EasyDNNnews/10570/img-Avalon-sustainability_credit-Avalon-Waterways.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C13",
    title: "Cunard Cruises",
    description: "Cunard Cruises is the epitome of oceanic elegance. Sail on legendary ships with a timeless sense of style, enjoy the finest cuisine, and traverse the seas in an atmosphere of sophistication.",
    place: "",
    pricing: "",
    image: "https://www.cruise365.com/wp-content/uploads/2019/03/Cunard_Line_Queen_Mary_2_Exterior_1.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C14",
    title: "Hurtigruten",
    description: "Hurtigruten invites you to explore the pristine landscapes of the Arctic and Antarctic. Embark on a journey of discovery, surrounded by breathtaking natural beauty and the comfort of their expedition vessels.",
    place: "",
    pricing: "",
    image: "https://cruise-with-points.marriott.com/images/web/338/1038/shp/ship_sm.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
  {
    id: "C15",
    title: "Silversea Cruise Line",
    description: "Silversea Cruise Line offers all-inclusive luxury voyages to the world's most extraordinary destinations. Enjoy sumptuous accommodations, gourmet cuisine, and personalized service as you travel in refined style.",
    place: "",
    pricing: "",
    image: "https://www.cruisemapper.com/images/ships/1618-c848d692585.jpg",
    rating: undefined,
    attractions: undefined,
    accommodation: undefined,
    adults: undefined,
    morePhotos: [],
  },
];

