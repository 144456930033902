import React, { useState } from "react";
import { Theme, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { Paper, ThemeProvider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useMediaQuery } from "@mui/material";
import { isSmallScreen } from "../../utilities/screen";

interface ModalCarouselProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
}

const theme = createTheme();

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  modalContent: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    padding: "20px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    overflowY: "auto",
    backgroundColor: "transparent",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    width: "auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "10%",
    backgroundColor: "#f0f0f0", // Background color for controls
  },
  controlButton: {
    backgroundColor: "#fff",
  },
}));

const ModalCarousel: React.FC<ModalCarouselProps> = ({
  images,
  isOpen,
  onClose,
}) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal open={isOpen} className={classes.modal} onClose={onClose}>
        <Paper
          className={classes.paper}
          sx={{ 
            height: isSmall ? "60%" : "90%",
            width: isSmall ? "100%" : "80%",

          
          }}
        >
          <div
            className={classes.modalContent}
            style={{ flexDirection: isSmall ? "column" : "row" }}
          >
            {!isSmall ? (
              <>
                <IconButton
                  className={`${classes.controlButton} ${classes.controls}`}
                  onClick={handlePrevClick}
                  color="primary"
                  sx={{
                    position: "absolute",
                    left: 0,
                    fontSize: 50,
                    backgroundColor: "transparent", // Remove background effect
                    "&:hover": {
                      backgroundColor: "transparent", // Remove hover background effect
                    },
                    "&:active": {
                      backgroundColor: "transparent", // Remove click background effect
                    },
                  }}
                >
                  <ArrowCircleLeftIcon
                    fontSize="inherit"
                    sx={{ color: "#DB504A" }}
                  />
                </IconButton>
                <img
                  className={classes.image}
                  src={images[currentIndex]}
                  alt={`Image ${currentIndex}`}
                  style={{ width: isSmall ? "100%" : "80%" }}
                />
                <IconButton
                  className={`${classes.controlButton} ${classes.controls}`}
                  onClick={handleNextClick}
                  color="primary"
                  sx={{
                    position: "absolute",
                    right: 0,
                    fontSize: 50,
                    backgroundColor: "transparent", // Remove background effect
                    "&:hover": {
                      backgroundColor: "transparent", // Remove hover background effect
                    },
                    "&:active": {
                      backgroundColor: "transparent", // Remove click background effect
                    },
                  }}
                >
                  <ArrowCircleRightIcon
                    fontSize="inherit"
                    sx={{ color: "#DB504A" }}
                  />
                </IconButton>
              </>
            ) : (
              <div style={{ width: "100%" }}>
                <img
                  className={classes.image}
                  src={images[currentIndex]}
                  alt={`Image ${currentIndex}`}
                  style={{ width: isSmall ? "100%" : "80%" }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <IconButton
                    className={`${classes.controlButton} ${classes.controls}`}
                    onClick={handlePrevClick}
                    color="primary"
                    sx={{
                      fontSize: 50,
                      backgroundColor: "transparent", // Remove background effect
                      "&:hover": {
                        backgroundColor: "transparent", // Remove hover background effect
                      },
                      "&:active": {
                        backgroundColor: "transparent", // Remove click background effect
                      },
                    }}
                  >
                    <ArrowCircleLeftIcon
                      fontSize="inherit"
                      sx={{ color: "#DB504A" }}
                    />
                  </IconButton>
                  <IconButton
                    className={`${classes.controlButton} ${classes.controls}`}
                    onClick={handleNextClick}
                    color="primary"
                    sx={{
                      fontSize: 50,
                      backgroundColor: "transparent", // Remove background effect
                      "&:hover": {
                        backgroundColor: "transparent", // Remove hover background effect
                      },
                      "&:active": {
                        backgroundColor: "transparent", // Remove click background effect
                      },
                    }}
                  >
                    <ArrowCircleRightIcon
                      fontSize="inherit"
                      sx={{ color: "#DB504A" }}
                    />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <Typography
            color={"white"}
            position={"absolute"}
            bottom={0}
            left={0}
            right={0}
            textAlign={"center"}
          >{`${currentIndex + 1} of ${images.length}`}</Typography>
        </Paper>
      </Modal>
    </ThemeProvider>
  );
};

export default ModalCarousel;
