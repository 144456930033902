import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import DirectionsIcon from "@mui/icons-material/Directions";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import Button from "@mui/material/Button";
import MapGL, { Marker } from "react-map-gl"; // Import MapGL and Marker
import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox CSS
import { Divider, useMediaQuery, useTheme } from "@mui/material";

export type StoreDetails = {
  id: string;
  name: string;
  location: string;
  isOpenToday: boolean;
  address: string;
  openingHours: object;
  phoneNumber: string;
  latitude: number;
  longitude: number;
};
export interface StoreLocationCardProps {
  storeDetails: StoreDetails;
}
const MAP_TOKEN =
  "pk.eyJ1IjoiY2hpc2hvbG1zaGV2b24iLCJhIjoiY2xtbTkwM2lhMGt1NzJxbXNsNGljMXVnMiJ9.hK-_lPTNuT8nGgwUQSIjpw";

const StoreLocationCard = ({ storeDetails }: StoreLocationCardProps) => {
  const {
    name,
    location,
    isOpenToday,
    address,
    openingHours,
    phoneNumber,
    latitude,
    longitude,
  } = storeDetails;

  const openingDays = Object.keys(openingHours);
  const hours = Object.values(openingHours);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const currentDate = new Date();
  const currentDay = currentDate.getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)

  // Map settings
  const mapStyle = "mapbox://styles/mapbox/streets-v11"; // Replace with your desired map style

  // Initial viewport settings
  const viewport = {
    latitude: 32.78029,
    longitude: -79.92787,
    zoom: 16, // Adjust the zoom level as needed
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleConnect = () => {
    const subject = `Appointment`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  }

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {/* Left: Location */}
          <Grid item xs={12} sm={6}>
            {/* Google Maps */}
            <MapGL
              {...viewport}
              style={{
                width: "100%",
                height: "100%",
              }}
              mapStyle={mapStyle}
              mapboxAccessToken={MAP_TOKEN}
              //   onViewportChange={(newViewport) => setViewport(newViewport)} // Handle viewport changes
            >
              {/* Marker */}
              <Marker
                latitude={viewport.latitude}
                longitude={viewport.longitude}
              >
                <LocationOnIcon fontSize="large" color="primary" />
              </Marker>
            </MapGL>
          </Grid>
          {/* Right: Store Info */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">{name}</Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} md={6}>
                {/* Address and Get Directions */}
                <Typography>{address}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Open Status */}
                {isOpenToday ? (
                  <Typography
                    color={"green"}
                    textAlign={isSmallScreen ? "left" : "right"}
                  >
                    <EventAvailableIcon fontSize="small" />
                    Open Today
                  </Typography>
                ) : (
                  <Typography
                    color="error"
                    textAlign={isSmallScreen ? "left" : "right"}
                  >
                    <EventBusyIcon fontSize="small" />
                    Closed Today
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ backgroundColor: "gray", width: "100%" }} />
              </Grid>
              <Grid item xs={12}>
                {/* Opening Hours */}
                <Typography
                  variant="h6"
                  display={"flex"}
                  alignItems={"center"}
                  fontWeight={"boler"}
                >
                  <AccessTimeIcon fontSize="small" sx={{ marginRight: 1 }} />
                  Opening Hours
                </Typography>
                {openingDays.map((day, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      color={
                        daysOfWeek[currentDay] === day && day !== "Sunday"
                          ? "green"
                          : day === "Sunday"
                          ? "red"
                          : "black"
                      }
                    >
                      {day}
                    </Typography>
                    <Typography
                      color={
                        daysOfWeek[currentDay] === day && day !== "Sunday"
                          ? "green"
                          : day === "Sunday"
                          ? "red"
                          : "black"
                      }
                    >
                      {hours[openingDays.indexOf(day)]}
                    </Typography>
                  </div>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ backgroundColor: "gray", width: "100%" }} />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* Phone Number */}
                <Typography>
                  <PhoneIcon fontSize="small" />
                  {phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} textAlign={"right"}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#165B76",
                    "&:hover": {
                      backgroundColor: "#165B76",
                    },
                    "&:focus": { outline: "none" },
                  }}
                  fullWidth={isSmallScreen}
                  onClick={handleConnect}
                >
                  Book Appointment
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StoreLocationCard;
