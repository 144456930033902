import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { createTheme } from "@mui/material";
import Breadcrumb from "../../components/Breadcrumbs";
import { Link, useLocation } from "react-router-dom";

const theme = createTheme({
  // Customize your theme here
  palette: {
    primary: {
      main: "#007bff",
    },
  },
});

const useStyles = makeStyles(() => ({
  exploreHeader: {
    fontWeight: "bold",
    textAlign: "center",
  },
  exploreCard: {
    position: "relative",
    height: "150px", // Adjust the height as needed
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    "&:hover::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  exploreImage: {
    width: "100%",
    height: "auto",
  },
  exploreContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    textAlign: "center",
  },
}));

const ExploreComponent = () => {
  const classes = useStyles();

  // Define sample data for cards
  const cardsData = [
    {
      id: 1,
      name: "Jamaica",
      imageUrl: "https://apiimg.iberostar.com/uploads/image/37580/image.jpeg",
    },
    {
      id: 2,
      name: "Myrtle Beach",
      imageUrl:
        "https://a.cdn-hotels.com/gdcs/production167/d1602/7fce964c-86d5-47a1-af25-405dbd0704cc.jpg?impolicy=fcrop&w=800&h=533&q=medium",
    },
    {
      id: 3,
      name: "Dominican Republic",
      imageUrl:
        "https://www.worldtravelguide.net/wp-content/uploads/2018/06/shu-NorthAmerica-DominicanRepublic-CapCana-411057478-PretoPerola-1441x823px.jpg",
    },
    {
      id: 4,
      name: "Puerto Rico",
      imageUrl: "https://media1.travelguide.de/media/c/pr.jpeg",
    },
    {
      id: 5,
      name: "Bahamas",
      imageUrl:
        "https://a.cdn-hotels.com/gdcs/production107/d1657/ce115d52-5bd1-4f78-8a2a-6f1ec518d239.jpg",
    },
    {
      id: 6,
      name: "Panama",
      imageUrl: "https://lp-cms-production.imgix.net/2022-04/LPT1212_020.jpg",
    },
  ];

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <Container>
        <Grid container spacing={2} marginBottom={10}>
          <Grid item lg={6} display={"flex"} alignItems={"center"}>
            <Typography variant="h2" className={classes.exploreHeader}>
              Uncover Top-notch Local Experiences
            </Typography>
          </Grid>
          <Grid item container spacing={2} lg={6}>
            {cardsData.map((card) => (
              <Grid item xs={12} sm={6} md={4} key={card.id}>
                <Link to={`/tours/${card.name}`}>
                  <Card
                    className={classes.exploreCard}
                    style={{ backgroundImage: `url(${card.imageUrl})` }}
                  >
                    <CardContent className={classes.exploreContent}>
                      <Typography
                        variant="h5"
                        fontWeight={"bold"}
                        style={{ textShadow: "2px 2px 4px rgba(0,0,0,0.5)" }}
                      >
                        {card.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ExploreComponent;
