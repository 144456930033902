import React from "react";
import Grid from "@mui/material/Grid";
import { cruiseDeals } from "../constants";
import { Container, Typography } from "@mui/material";
import CruiseDealCard from "./VacationCard";

const CruiseDeals = () => {
  return (
    <Container sx={{ marginBottom: 10 }}>
      {" "}
      <Typography
        variant="h4"
        sx={{ color: "#165B76", textAlign: "center", marginBottom: 4 }}
      >
        Cruise Lines
      </Typography>
      <Grid container spacing={2}>
        {cruiseDeals.map((deal, index) => (
          <Grid item xs={12} sm={6} lg={4} key={index}>
            <CruiseDealCard deal={deal} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default CruiseDeals;
