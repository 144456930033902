import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import Cookies from "js-cookie";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  initDealsFromCookies,
  saveCruiseDeal,
  unsaveCruiseDeal,
} from "../../../../redux/store/index";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  vacationDealCardContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    height: 450,
  },
  vacationDealCard: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 100, // Ensure that cards take up all available vertical space
  },
  card: {
    "&:hover": {
      transform: "scale(1.05)", // Scale the card on hover
      transition: "transform 1s ease-in-out", // Adjust the duration to make it slower
    },
    paddingBottom: 0,
  },
}));

export enum attractions {
  plane = "plane",
  bus = "bus",
  car = "car",
  allInclusive = "allInclusive",
  rooms = "rooms",
  camera = "camera",
  adult = "adult",
}

export type CruiseDeal = {
  id: string;
  title: string;
  place: string;
  pricing: string;
  image: string;
  rating: number | undefined;
  attractions: attractions[] | undefined;
};
interface VacationDealCardProps {
  deal: CruiseDeal;
}

export const CruiseDealCard = ({ deal }: VacationDealCardProps) => {
  const [saved, setSaved] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const savedDeals = useSelector(
    (state: RootState) => state.savedDeals.cruiseDeals
  );
  const isSaved = savedDeals.includes(deal.id);

  const handleSaveCruiseDeal = (dealId: string) => {
    dispatch(saveCruiseDeal(dealId));
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const newSavedDeals = [...parsedDeals.cruiseDeals, dealId];
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: parsedDeals.vacationDeals,
          cruiseDeals: newSavedDeals,
        }),
        { expires: 365 }
      );
    } else {
      Cookies.set(
        "savedDeals",
        JSON.stringify({ vacationDeals: [], cruiseDeals: [dealId] }),
        { expires: 365 }
      );
    }
  };

  const handleUnsaveCruiseDeal = (dealId: string) => {
    dispatch(unsaveCruiseDeal(dealId));
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      const filteredDeals = parsedDeals.cruiseDeals.filter(
        (id) => id !== dealId
      );
      Cookies.set(
        "savedDeals",
        JSON.stringify({
          vacationDeals: parsedDeals.vacationDeals,
          cruiseDeals: filteredDeals,
        }),
        { expires: 365 }
      );
    }
  };

  const handleSaveClick = () => {
    if (isSaved) {
      console.log("Unsaved");
      handleUnsaveCruiseDeal(deal.id);
    } else {
      console.log("saved");
      handleSaveCruiseDeal(deal.id);
    }
  };

  // Dispatch the action to initialize saved deals from cookies
  useEffect(() => {
    const savedDeals = Cookies.get("savedDeals");
    if (savedDeals) {
      const parsedDeals: { vacationDeals: string[]; cruiseDeals: string[] } =
        JSON.parse(savedDeals);
      if (parsedDeals.cruiseDeals && parsedDeals.vacationDeals) {
        dispatch(initDealsFromCookies(parsedDeals));
        setSaved(parsedDeals.cruiseDeals.includes(deal.id));
      }
    }
  }, [deal.id, dispatch]);

  return (
    <>
      <Card className={classes.card}>
        <Link
          to={`/cruises/deal/?title=${deal.title}&id=${deal.id}`}
          style={{ textDecoration: "none" }}
        >
          <CardMedia
            component="img"
            alt={deal.title}
            height="250"
            image={deal.image}
          />
          <CardContent sx={{ paddingBottom: 0 }}>
            <div className={classes.vacationDealCard}>
              <div>
                <Typography
                  variant="h6"
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {deal.title}
                </Typography>
              </div>
              <Divider className="bright-divider" />
            </div>
          </CardContent>
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            <span className="attraction-icon" style={{ color: "gray" }}>
              <Tooltip title="Cruise">
                <DirectionsBoatIcon />
              </Tooltip>
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={(event) => {
                handleSaveClick();
              }}
            >
              {isSaved ? (
                <FavoriteIcon style={{ color: "#DB504A" }} />
              ) : (
                <FavoriteBorderIcon style={{ color: "#DB504A" }} />
              )}
            </IconButton>
            <Typography
              variant="body2"
              style={{ color: isSaved ? "#DB504A" : "" }}
            >
              {isSaved ? "Saved" : "Save"}
            </Typography>
          </div>
        </div>
      </Card>
    </>
  );
};

export default CruiseDealCard;
