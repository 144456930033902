import { StoreDetails } from "./StoreLocationCard";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const currentDate = new Date();
const currentDay = currentDate.getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)

const dayName = daysOfWeek[currentDay];
console.log("Today is " + dayName);

export const storeData: StoreDetails[] = [
    {
      id: 'store1',
      name: 'Caribbean World Explorer',
      location: '215 East Bay St. Ste. 201k Charleston SC 29401',
      isOpenToday: daysOfWeek[currentDay] !== "Sunday",
      address: '215 East Bay St. Ste. 201k Charleston SC 29401',
      openingHours: {
        Monday: '9:00 AM - 6:00 PM',
        Tuesday: '9:00 AM - 6:00 PM',
        Wednesday: '9:00 AM - 6:00 PM',
        Thursday: '9:00 AM - 6:00 PM',
        Friday: '9:00 AM - 6:00 PM',
        Saturday: '10:00 AM - 4:00 PM',
        Sunday: 'Closed',
      },
      phoneNumber: '+1 (854) 233-0993',
      latitude: 32,
      longitude: -79
    },
   
  ];
  