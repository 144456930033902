import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import { Typography } from "@mui/material";
import CruisesHero from "./CruisesHero";
import CruiseDeals from "./VacationDeals";
import PopularCruiseDestinations from "./PopularCruises";
import { useLocation } from "react-router-dom";

export default function () {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch />
      <CruisesHero />
      <CruiseDeals />
      <PopularCruiseDestinations />
    </div>
  );
}
