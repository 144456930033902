import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Define your state type
interface DealsState {
  savedDeals: {
    vacationDeals: string[];
    cruiseDeals: string[];
  };
}

// Define your initial state
const initialState: DealsState = {
  savedDeals: {
    vacationDeals: [],
    cruiseDeals: [],
  },
};

// Define your slice
const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    saveVacationDeal: (state, action: PayloadAction<string>) => {
      state.savedDeals.vacationDeals.push(action.payload);
    },
    unsaveVacationDeal: (state, action: PayloadAction<string>) => {
      state.savedDeals.vacationDeals = state.savedDeals.vacationDeals.filter(
        (dealId) => dealId !== action.payload
      );
    },
    saveCruiseDeal: (state, action: PayloadAction<string>) => {
      state.savedDeals.cruiseDeals.push(action.payload);
    },
    unsaveCruiseDeal: (state, action: PayloadAction<string>) => {
      state.savedDeals.cruiseDeals = state.savedDeals.cruiseDeals.filter(
        (dealId) => dealId !== action.payload
      );
    },
    initDealsFromCookies: (state, action: PayloadAction<{ vacationDeals: string[]; cruiseDeals: string[] }>) => {
      state.savedDeals = action.payload;
    },
  },
});

// Export your actions
export const {
  saveVacationDeal,
  unsaveVacationDeal,
  saveCruiseDeal,
  unsaveCruiseDeal,
  initDealsFromCookies,
} = dealsSlice.actions;

// Define Redux Persist configuration
const persistConfig: PersistConfig<DealsState> = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, dealsSlice.reducer);

// Create your Redux store
export const store = configureStore({
  reducer: persistedReducer,
});
export type RootState = ReturnType<typeof store.getState>;

// Create a persistor object to persist the store
export const persistor = persistStore(store);
