import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Container, CssBaseline } from "@mui/material";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Layout from "./components/Layout";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import LandingPage from "./pages/LandingPage";
import VacationsPage from "./pages/VacationsPage";
import DestinationsPage from "./pages/DestinationsPage";
import CruisesPage from "./pages/CruisesPage";
import GroupTravelPage from "./pages/GroupTravelPage";
import ContactUs from "./pages/ContactUs";
import SavedDealsPage from "./pages/WanderList";
import StoreLocations from "./pages/StoreLocations";
import Deal from "./pages/Deal";
import ExplorerRewards from "./pages/ExplorerRewards";
import Rewards from "./pages/Rewards";
import LocationProvider from "./components/LocationProvider";
import TourPlace from "./pages/TourPlace";
import TermsAndConditionsPage from "./pages/TermsAndConditions";
import FAQPage from "./pages/FaqPage";

function App() {
  return (
    <Router>
      <CssBaseline />
      <Navbar />
      <div className="main-container">
        <LocationProvider>
          <RoutesWithAnimation />
        </LocationProvider>
      </div>
      <Footer />
    </Router>
  );
}

export default App;

function RoutesWithAnimation() {
  const location = useLocation();

  return (
    <Routes location={location} key={location.key}>
      <Route
        path="/"
        element={
          <Layout>
            <LandingPage />
          </Layout>
        }
      />
      <Route
        path="/vacations"
        element={
          <Layout>
            <VacationsPage />
          </Layout>
        }
      />
      <Route
        path="/destinations"
        element={
          <Layout>
            <DestinationsPage />
          </Layout>
        }
      />
      <Route
        path="/cruises"
        element={
          <Layout>
            <CruisesPage />
          </Layout>
        }
      />
      <Route
        path="/group-travel"
        element={
          <Layout>
            <GroupTravelPage />
          </Layout>
        }
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route
        path="/wanderlist"
        element={
          <Layout>
            <SavedDealsPage />
          </Layout>
        }
      />
      <Route
        path="/stores"
        element={
          <Layout>
            <StoreLocations />
          </Layout>
        }
      />
      <Route
        path="/faq"
        element={
          <Layout>
            <FAQPage />
          </Layout>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditionsPage />}
      />
      <Route
        path="/vacations/deal"
        element={
          <Layout>
            <Deal />
          </Layout>
        }
      />

      <Route
        path="/cruises/deal"
        element={
          <Layout>
            <Deal />
          </Layout>
        }
      />

      <Route
        path="/tours"
        element={
          <Layout>
            <ExplorerRewards />
          </Layout>
        }
      />
      <Route
        path="/tours/:place"
        element={
          <Layout>
            <Rewards />
          </Layout>
        }
      />
      <Route
        path="/tours/:place/:tour"
        element={
          <Layout>
            <TourPlace />
          </Layout>
        }
      />
    </Routes>
  );
}
