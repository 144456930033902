import React from "react";
import { Carousel } from "react-responsive-carousel";
import { HEROIMAGES } from "./constants";

export default function HeroCarousel() {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="carousel-overlay"></div>

          <img
            src={require("../../../assets/slide3.jpg")}
            className="d-block w-100"
            alt="Photo by JESHOOTS.COM on Unsplash"
          />
          <div
            className="carousel-caption d-none d-md-block"
            style={{
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              fontWeight: "bold",
            }}
          >
            <div>
              <h3>EXPLORE YOUR WORLD</h3>
            </div>
            <p>
              We’ll plan and book your dream vacation.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="carousel-overlay"></div>
          <img
            src={require("../../../assets/slide2.jpg")}
            className="d-block w-100"
            alt="Photo by Limor Zellermayer on Unsplash"
          />
          <div
            className="carousel-caption d-none d-md-block"
            style={{
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              fontWeight: "bold",
            }}
          >
            <div>
              <h3>CREATE MEMORIES BEYOND COMPARE</h3>
            </div>
            <p>
              We’ll tailor your vacation package to your preferences and desires.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="carousel-overlay"></div>

          <img
            src={require("../../../assets/slide1.jpg")}
            className="d-block w-100"
            alt="Image by Freepik"
          />
          <div
            className="carousel-caption d-none d-md-block"
            style={{
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              fontWeight: "bold",
            }}
          >
            <div>
              <h3>LIVE. LAUGH. LOVE. EXPLORE.</h3>
            </div>
            <p>
              Discover the world with us and create unforgettable memories.
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <div className="carousel-overlay"></div>

          <img
            src={require("../../../assets/slide4.jpg")}
            className="d-block w-100"
            alt="..."
          />
          <div
            className="carousel-caption d-none d-md-block"
            style={{
              textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
              fontWeight: "bold",
            }}
          >
            <div>
              <h3>EXPLORE YOUR DREAM DESTINATION WEDDING</h3>
            </div>
            <p>
              Let us turn your dream wedding into a breathtaking destination celebration, where love and adventure meet in the most enchanting settings.
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
