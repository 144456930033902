import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import DestinationsHero from "./DestinationsHero";
import TropicalDestinations from "./TropicalDestinations";
import { Typography } from "@mui/material";
import TopDestinations from "./TopDestinations";
import { useLocation } from "react-router-dom";

export default function () {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch={false} />
      <DestinationsHero />
      
      <TropicalDestinations />
      <TopDestinations />
    </div>
  );
}
