import React, { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumbs";
import VacationDealsGrid from "./VacationDeals";
import VacationPackages from "./VacationPackages";
import ResortTypes from "./ResortTypes";
import ResortTypesGlance from "./ResortTypesGlance";
import { Box, Typography } from "@mui/material";
import { PACKAGES_INFO } from "./constants";
import { useLocation } from "react-router-dom";

export default function () {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const {
    package_actionText,
    package_description,
    package_image,
    package_route,
    package_title,
  } = PACKAGES_INFO;
  return (
    <div style={{ marginTop: 96 }}>
      <Breadcrumb showSearch />
      <VacationPackages
        image={package_image}
        description={package_description}
        route={package_route}
        title={package_title}
        actionText={package_actionText}
      />
      <Box sx={{ padding: { lg: 10, sm: 1, xs: 1 }, margin: 0 }}>
        <VacationDealsGrid />
      </Box>
      <section>
        <Typography
          variant="h4"
          sx={{ color: "#165B76", textAlign: "center", marginBottom: 4 }}
        >
          Resort Types
        </Typography>
        <ResortTypes />
      </section>
      <ResortTypesGlance />
    </div>
  );
}
