import React from "react";
import Cta from "../Cta";
import { motion } from "framer-motion";
import ScrollToTop from "../ScrollToTop";

const routeVariants = {
  initial: {
    opacity: 0,
  },
  final: {
    opacity: 1,
    transition: {
      ease: [0.6, 0.05, 0.19, 0.9], // Valid cubic bezier easing function
      duration: 0.8, // Animation duration in seconds
    },
  },
};

const childVariants = {
  initial: {
    opacity: 0,
    y: "50px",
  },
  final: {
    opacity: 1,
    y: "0px",
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
};

function Layout({ children }: any) {
  const handleEmailClick = () => {
    // Replace 'email@example.com' with your email address
    const emailAddress = "info@caribbeanworldexplorer.com";

    // Compose the email subject and body
    const subject = "Service Enquiry";
    const body = "Hello,\n\nI have a question about your service.";

    // Construct the mailto link
    const mailtoLink =
      "mailto:" +
      emailAddress +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(body);

    // Open the user's email system
    window.location.href = mailtoLink;
  };

  return (
    <motion.div variants={routeVariants} initial="initial" animate="final">
      <ScrollToTop />

      {children}
      <Cta />
      <div id="email-button-container" onClick={handleEmailClick}>
        <i className="fas fa-envelope" id="email-icon"></i>
        <span id="email-text">Email Us</span>
      </div>
    </motion.div>
  );
}

export default Layout;
