import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  ThemeProvider,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Rating,
  useTheme,
  createTheme,
  Box,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { makeStyles } from "@mui/styles";

// Define a custom theme
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles(() => ({
  testimonialContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center vertically
    width: "80%", // Span 33% of the width by default
    margin: "0 auto", // Center horizontally
    padding: theme.spacing(2),
    height: 400,
    [theme.breakpoints.down("sm")]: {
      width: "72%", // Span 100% width on small screens
    },
    [theme.breakpoints.up("lg")]: {
      alignItems: "center",
    },
  },
  avatar: {
    borderRadius: "50%",
    marginBottom: theme.spacing(2), // Add margin on small screens
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%", // Span 100% of the width
  },
  title: {
    color: "#DB504A",
  },
  subheader: {
    color: "#165B76",
  },
  rating: {
    marginTop: 1,
    marginLeft: 0,
  },
  review: {
    color: "#165B76",
    marginTop: theme.spacing(1),
  },
  carousel: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  indicator: {
    color: "#165B76",
  },
  prevIcon: {
    [theme.breakpoints.down("sm")]: {
      bottom: 0, // Span 100% width on small screens
    },
  },
  nextIcon: {
    [theme.breakpoints.down("sm")]: {
      bottom: 0, // Span 100% width on small screens
    },
  },
}));

export type Testimonial = {
  avatar: string;
  rating: number;
  name: string;
  review: string;
  date: string;
};

interface TestimonialsProps {
  testimonials: Testimonial[];
}

export default function Testimonials({ testimonials }: TestimonialsProps) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ marginBottom: 5, marginTop: 5 }}>
        <Typography variant="h4" sx={{ color: "#165B76", textAlign: "center" , marginBottom: 10}}>
          Testimonials
        </Typography>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={5000}
          centerMode={false}
          containerClass="container-with-dots"
          customTransition="transform 300ms ease-in-out"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              partialVisibilityGutter: 20, 
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              // partialVisibilityGutter: 20, 
            },
            mobile: {
              breakpoint: { max: 720, min: 0 },
              items: 1,
              // partialVisibilityGutter: 20, 
            },
          }}
          showDots
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
          {testimonials.map((testimonial, index) => (
            <Card key={index} className={classes.testimonialContainer}>
              <Avatar
                src={testimonial.avatar}
                alt={testimonial.name}
                className={classes.avatar}
                sx={{
                  width: 100,
                  height: 100
                }}
              />
              <CardContent className={classes.contentContainer}>
                <div>
                  <CardHeader
                    title={testimonial.name}
                    subheader={testimonial.date}
                    sx={{
                      margin: 0,
                      padding: 0,
                    }}
                    titleTypographyProps={{ className: classes.title }}
                    subheaderTypographyProps={{ className: classes.subheader }}
                  />
                  <Rating
                    name={`rating-${index}`}
                    value={testimonial.rating}
                    readOnly
                    max={5}
                    className={classes.rating}
                  />
                </div>
                <Typography variant="body1" className={classes.review}>{`"${testimonial.review}"`}</Typography>
              </CardContent>
            </Card>
          ))}
        </Carousel>
      </Box>
    </ThemeProvider>
  );
}
