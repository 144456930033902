import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, useMediaQuery, useTheme, Box } from "@mui/material";

const image =
  "https://s3.amazonaws.com/a-us.storyblok.com/f/1005231/ba41ceb059/carnival-cruise-line_carnival-imagination_81.jpg";
const title = "Cruise Vacations with CWE";
const description =
  "Explore the world on a cruise. Ships boast dazzling amenities, including 5-star accommodations and dining, onboard thrills from casinos to water slides, and unique cultural opportunities. Explore the high seas on an ocean cruise or experience a country’s interior on a river cruise. CWE's cruise packages make it easier than ever to discover the world.";
const actionText = "Start Planning";
const route = "";

export default function CruisesHero() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleConnect = () => {
    const subject = `Cruises - Start Planning`;
    const mailtoLink = `mailto:info@caribbeanworldexplorer.com?subject=${encodeURIComponent(
      subject
    )}`;

    window.location.href = mailtoLink;
  };

  return (
    <Container sx={{marginTop: isSmallScreen ? 5 : 10, marginBottom: isSmallScreen ? 5 : 10 }}>
      <Card
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          alignItems: "stretch", // Change to "stretch"
          minHeight: "200px",
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: isSmallScreen ? "100%" : "60%",
            height: 650, // Change to "auto"
            marginBottom: isSmallScreen ? 2 : 0,
            marginRight: !isSmallScreen ? 3 : 0,
          }}
          image={image}
          alt="Card Image"
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems:  isSmallScreen ? "center" : "flex-start", // Center on small screens
            width: isSmallScreen ? "100%" : "40%",
            height: "100%",
            maxHeight: "100%",
            paddingLeft: isSmallScreen ? 0 : "auto"
          }}
        >
          <Typography
            variant="body1"
            component="div"
            width={"100%"}
            textAlign={"left"}
            sx={{
              marginBottom: "5%", // Space below the title
              color: "black",
              fontWeight: "bold",
              fontSize: {
                xs: "1.5rem",
                sm: "1.8rem",
                md: "1.9rem",
                lg: "2.5rem",
              },
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: "5%", // Space below the description
              width: !isSmallScreen ? "90%" : "100%",
              color: "black",
              fontSize: {
                xs: "1rem",
                sm: "1.3rem",
                md: "1.1rem",
                lg: "1.2rem",
              },
            }}
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#DB504A",
              textTransform: "none",
              width: isSmallScreen ? "40%" : "35%",
              height: {
                xs: 50,
              },
              fontSize: {
                xs: "0.8rem",
                lg: "1rem",
              },
              "&:hover": {
                backgroundColor: "#165B76", // Change the background color on hover
                color: "white",
              },
            }}
            onClick={handleConnect}
          >
            {actionText}
          </Button>
        </CardContent>
      </Card>
      {/* </Container> */}
    </Container>
  );
}
