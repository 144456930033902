import React from "react";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Button, ThemeProvider, Typography, createTheme, useMediaQuery } from "@mui/material";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StarRateIcon from "@mui/icons-material/StarRate";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import StarIcon from "@mui/icons-material/Star";
import EmailIcon from "@mui/icons-material/Email"; // Import the Email icon

const theme = createTheme();


const useStyles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "left",
    maxHeight: "100%",
    overflowY: "auto",
  },
  heading: {
    backgroundColor: "#DB504A",
    color: "white",
    fontSize: "1.5rem",
    fontWeight: "bold",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  agentCard: {
    backgroundColor: "#165B76",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  expertsContainer: {
    padding: theme.spacing(2),
  },
  contentContainer: {
    padding: theme.spacing(1),
  },
  expertName: {
    color: "white",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  expertBio: {
    color: "white",
    fontSize: "1rem",
  },
  expertEmail: {
    color: "gray",
    fontWeight: "bold",
    fontSize: "0.8rem",
  },
  expertYears: {
    color: "gold",
    fontSize: "0.8rem",
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: 150,
    height: 150,
    boxShadow: theme.shadows[10],
  },
  valueItem: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  valueIcon: {
    fontSize: "3.5rem", // Adjust the icon size as needed,
    color: "#DB504A",
  },
  valuesContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  valueText: {
    fontSize: "0.8rem",
  },
  grabberText: {
    textAlign: "center",
    fontWeight: "bolder",
  },
  emailUsButton: {
    marginTop: theme.spacing(2),
    backgroundColor: "#DB504A",
    color: "white",
    "&:hover": {
      backgroundColor: "#C53727",
    },
    width: "auto",
  },
};

const travelExperts = [
  {
    name: "Sharmeel Williams",
    email: "info@caribbeanworldexplorer.com",
    avatar: "",
    bio: `"Celebrating the beauty of our world, I'm your dedicated travel expert. With a passion for exploration and an eye for unforgettable experiences, I'm here to guide you on your journey to discover new horizons and create lasting memories. Let's turn your travel dreams into reality, one destination at a time."`,
    yearsOfExperience: 4,
  },
  // {
  //   name: "Winston Huges",
  //   email: "jane@example.com",
  //   avatar:
  //     "https://kevsbest.com/wp-content/uploads/2022/02/5-Best-Travel-Agent-in-Omaha.png",
  //   bio: "Jane specializes in luxury travel and creating memorable experiences.",
  //   yearsOfExperience: 3,
  // },
  // Add more travel experts as needed
];

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const handleEmailUsClick = () => {
  // Replace 'your-email@example.com' with the actual email address
  const emailAddress = "sgwilliams@caribbeanworldexplorer.com";
  const subject = "Service Enquiry"; // Optional subject
  const body = "Hello, I have a question..."; // Optional email body

  // Create the mailto link
  const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  // Open the user's email system with the mailto link
  window.location.href = mailtoLink;
};

function TravelExpertsModal({ open, onClose }: ModalProps) {

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={theme}>
      <Modal open={open} onClose={onClose} sx={useStyles.modal}>
        <Paper sx={{...useStyles.paper, maxWidth: isSmall ? "100%" : "80%"}} >
          <div style={useStyles.heading}>
            Meet Our Travel Experts
            <IconButton color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Grid container spacing={2} sx={useStyles.expertsContainer}>
            {travelExperts.map((expert, index) => (
              <Grid item xs={12} key={index}>
                <Tooltip title={expert.bio} arrow>
                  <Card sx={useStyles.agentCard}>
                    <CardContent sx={useStyles.contentContainer}>
                      <Grid container spacing={1}>
                        <Grid item lg={3} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                          <Avatar
                            src={
                              expert.avatar || require("../../assets/sharmeel.png")
                            }
                            sx={useStyles.avatar}
                          />
                        </Grid>
                        <Grid item xs={12} lg={9}>
                          <div style={useStyles.expertName}>{expert.name}</div>
                          <div style={useStyles.expertEmail}>
                            {expert.email}
                          </div>
                          <div style={useStyles.expertYears}>
                            <StarIcon sx={{ fontSize: 16, margin: 0 }} />{" "}
                            {expert.yearsOfExperience}+ Years
                          </div>
                          <div style={useStyles.expertBio}>{expert.bio}</div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography sx={useStyles.grabberText}>
              We Are Here With You Every Step Of The Way!
            </Typography>
            <Button
              variant="contained"
              sx={useStyles.emailUsButton}
              startIcon={<EmailIcon />} // Add the Email icon
              onClick={handleEmailUsClick} // Call the function to open the email system
              fullWidth
            >
              Email Us
            </Button>
          </div>
          <Grid container spacing={2} sx={useStyles.valuesContainer}>
            {/* Value 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{ ...useStyles.valueItem, boxShadow: "none" }}
              >
                <div style={useStyles.valueIcon}>
                  <LocalPoliceIcon fontSize="inherit" color="inherit" />
                </div>
                <Typography
                  variant="h6"
                  color={"#165B76"}
                  sx={{ fontWeight: "bold" }}
                >
                  Trust
                </Typography>
                <Typography
                  variant="body1"
                  color={"black"}
                  sx={useStyles.valueText}
                >
                  Dependable, Always
                </Typography>
              </Paper>
            </Grid>

            {/* Value 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{ ...useStyles.valueItem, boxShadow: "none" }}
              >
                <div style={useStyles.valueIcon}>
                  <StarRateIcon fontSize="inherit" color="inherit" />
                </div>
                <Typography
                  variant="h6"
                  color={"#165B76"}
                  sx={{ fontWeight: "bold" }}
                >
                  Value
                </Typography>
                <Typography
                  variant="body1"
                  color={"#black"}
                  sx={useStyles.valueText}
                >
                  Value Beyond Compare
                </Typography>
              </Paper>
            </Grid>

            {/* Value 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{ ...useStyles.valueItem, boxShadow: "none" }}
              >
                <div style={useStyles.valueIcon}>
                  <ContactSupportIcon fontSize="inherit" color="inherit" />
                </div>
                <Typography
                  variant="h6"
                  color={"#165B76"}
                  sx={{ fontWeight: "bold" }}
                >
                  Advice
                </Typography>
                <Typography
                  variant="body1"
                  color={"#black"}
                  sx={useStyles.valueText}
                >
                  Expert Advice, Tailored Solutions
                </Typography>
              </Paper>
            </Grid>

            {/* Value 2 */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{ ...useStyles.valueItem, boxShadow: "none" }}
              >
                <div style={useStyles.valueIcon}>
                  <VolunteerActivismIcon fontSize="inherit" color="inherit" />
                </div>
                <Typography
                  variant="h6"
                  color={"#165B76"}
                  sx={{ fontWeight: "bold" }}
                >
                  Service
                </Typography>
                <Typography
                  variant="body1"
                  color={"#black"}
                  sx={useStyles.valueText}
                >
                  Customer-Centric Excellence
                </Typography>
              </Paper>
            </Grid>

            {/* Add two more value items with the same structure */}
          </Grid>
        </Paper>
      </Modal>
    </ThemeProvider>
  );
}

export default TravelExpertsModal;
